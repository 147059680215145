const languageMapping: Record<string, string> = {
  en: 'en-US',
  fr: 'fr-FR',
  pt: 'pt-BR',
  es: 'es-ES',
  it: 'it-IT'
  // add as needed
};

export const languageNames: Record<string, string> = {
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'pt-BR': 'Português'
  // add as needed
};

export const languageFlagMapping: Record<string, string> = {
  'en-US': '🇺🇸',
  'es-ES': '🇪🇸',
  'fr-FR': '🇫🇷',
  'it-IT': '🇮🇹',
  'pt-BR': '🇧🇷'
};

export function validateAndConvertLanguage(language: string): string {
  const regex = /^[a-z]{2}-[A-Z]{2}$/; // Regex to validate language-country code

  const [lang, country] = language.split('-');
  const langCode = country ? `${lang}-${country.toUpperCase()}` : lang;

  if (regex.test(langCode)) {
    return langCode;
  } else if (languageMapping[lang]) {
    return languageMapping[lang];
  }

  // Default
  return 'en-US';
}

export function getLanguageName(languageCode: string): string {
  return languageNames[languageCode] || 'Unknown Language';
}

export function getLanguageWithFlag(languageCode: string): string {
  const flag = languageFlagMapping[languageCode] || '🏳️';
  const [lang, _country] = languageCode.split('-');
  return `${flag} ${lang.toUpperCase()}`;
}
