import { useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { projectSettingsState } from 'state/project';

import FeedbackSelect from './FeedbackSelect';
import SearchBar from './SearchBar';

export default function Filters() {
  const pSettings = useRecoilValue(projectSettingsState);

  if (pSettings?.ui?.hide_filter_search) {
    return null;
  }

  return (
    <Box sx={{ px: 1.5 }}>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1
        }}
      >
        <SearchBar />
        <FeedbackSelect />
      </Stack>
    </Box>
  );
}
