import { Box } from '@mui/material';

import { FormInput, TFormInput } from '@chainlit/react-components';

import MediaSelector, { MediaSelectorProps } from './mediaSelectorInput';

export type TCustomFormInput = TFormInput | MediaSelectorProps;

interface CustomFormInputProps {
  element: TCustomFormInput;
  visible?: boolean;
}

const Component = ({ element }: { element: TCustomFormInput }) => {
  if (element.type === 'media') {
    return <MediaSelector {...element} />;
  } else {
    return <FormInput element={element} />;
  }
};

const CustomFormInput = ({ element, visible }: CustomFormInputProps) => {
  return (
    <Box
      sx={{
        display: visible ? undefined : 'none'
      }}
    >
      <Component element={element} />
    </Box>
  );
};

export default CustomFormInput;
