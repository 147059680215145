import { imageElementsMatch } from 'helpers/element';
import { useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material';

import { ImageThreadElement } from '@chainlit/react-client';

import { useImageUrl } from 'hooks/image';

interface ImageItemProps {
  item: ImageThreadElement;
  toggleCandidateImage: (item: ImageThreadElement) => void;
  candidateImage: ImageThreadElement | null;
  translationKey: string;
  t: (key: string) => string;
}

const ImageItem = ({
  item,
  toggleCandidateImage,
  candidateImage,
  translationKey,
  t
}: ImageItemProps) => {
  const [visible, setVisible] = useState(false);
  const url = useImageUrl(item);

  return (
    <ImageListItem key={url} sx={{ display: visible ? 'block' : 'none' }}>
      <img
        onClick={() => toggleCandidateImage(item)}
        src={`${url}`}
        onLoad={() => setVisible(true)}
      />
      <ImageListItemBar
        sx={{
          background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)'
        }}
        title={
          imageElementsMatch(item, candidateImage)
            ? t(`${translationKey}.selected`)
            : ''
        }
        position="top"
        actionIcon={
          <IconButton
            sx={{ color: 'white' }}
            onClick={() => toggleCandidateImage(item)}
          >
            {imageElementsMatch(item, candidateImage) ? (
              <StarIcon />
            ) : (
              <StarBorderIcon />
            )}
          </IconButton>
        }
        actionPosition="left"
      />
    </ImageListItem>
  );
};

export default ImageItem;
