import Circle from '@mui/icons-material/Circle';
import type { SxProps } from '@mui/material';

const RedDot = (props: SxProps) => (
  <Circle
    sx={{
      position: 'absolute',
      transform: 'scale(0.5)',
      top: 0,
      right: 0,
      color: '#ff0000',
      ...props
    }}
  />
);

export default RedDot;
