import { imageElementsMatch } from 'helpers/element';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, IconButton, Tooltip } from '@mui/material';

import { ImageThreadElement } from '@chainlit/react-client';
import { Attachment } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import { useAttachmentImageUrl } from 'hooks/image';

import { IAttachment } from 'state/chat';

interface AttachmentItemProps {
  attachment: IAttachment;
  removeAttachment: () => void;
  toggleCandidateImage: (item: ImageThreadElement) => void;
  candidateImage: ImageThreadElement | null;
}

const AttachmentItem = ({
  attachment,
  removeAttachment,
  toggleCandidateImage,
  candidateImage
}: AttachmentItemProps) => {
  const item: ImageThreadElement | null =
    attachment.serverId && attachment.threadId
      ? {
          elementId: attachment.serverId,
          threadId: attachment.threadId
        }
      : null;

  const url = useAttachmentImageUrl(attachment);

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        sx={{
          position: 'relative'
        }}
        onClick={() => item && toggleCandidateImage(item)}
      >
        <Attachment
          key={attachment.id}
          name={attachment.name}
          mime={attachment.type}
          selected={imageElementsMatch(attachment, candidateImage)}
          url={url}
          alwaysShowName={true}
        />
      </Button>
      <Tooltip
        title={
          <Translator path="components.molecules.attachments.removeAttachment" />
        }
      >
        <IconButton
          sx={{
            position: 'absolute',
            p: 0.5,
            right: 0,
            top: -2,
            background: 'white',
            backgroundColor: 'background.paper',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            '&:hover': {
              backgroundColor: 'background.default'
            }
          }}
          onClick={removeAttachment}
        >
          <DeleteIcon
            sx={(theme) => ({
              height: 20,
              width: 20,
              [theme.breakpoints.down('sm')]: {
                height: 12,
                width: 12
              }
            })}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AttachmentItem;
