import { apiClient } from 'api';
import { getLanguageName, languageNames } from 'i18n/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useApi } from '@chainlit/react-client';

import { IProjectSettings } from 'state/project';
import { settingsState } from 'state/settings';

interface LanguageSelectorProps {
  availableLanguages: string[];
}

function LanguageSelector({ availableLanguages }: LanguageSelectorProps) {
  const { i18n } = useTranslation();
  const [settings, setSettings] = useRecoilState(settingsState);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [_languageData, setLanguageData] = useState<IProjectSettings | null>(
    null
  );

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    localStorage.setItem('selectedLanguage', newLanguage);
    setSettings((old) => ({
      ...old,
      language: newLanguage
    }));
  };

  const { data: newLanguageData } = useApi<IProjectSettings>(
    apiClient,
    `/project/settings?language=${settings.language}`
  );

  useEffect(() => {
    // Update the language bundle and i18n if new language data is available
    if (newLanguageData && newLanguageData.translation) {
      setLanguageData(newLanguageData);
      i18n.addResourceBundle(
        settings.language,
        'translation',
        newLanguageData.translation
      );
      i18n.changeLanguage(settings.language);
    }
  }, [newLanguageData, settings.language, i18n]);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <Select
      value={selectedLanguage}
      onChange={handleLanguageChange}
      displayEmpty
      sx={{
        background: 'transparent',
        color: 'text.primary',
        border: 'none',
        fontSize: '14px',
        '& .MuiSelect-icon': {
          display: 'none'
        },
        '& .MuiSelect-select': {
          p: '8px 15px !important'
        }
      }}
    >
      {Object.keys(languageNames)
        .filter((langCode) => availableLanguages.includes(langCode))
        .map((langCode) => (
          <MenuItem value={langCode} key={langCode}>
            {`${getLanguageName(langCode)}`}
          </MenuItem>
        ))}
    </Select>
  );
}

export default LanguageSelector;
