import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://aa0eb607516d38dc695e403f607a38a7@o1345497.ingest.us.sentry.io/4507495964409856',
  // `environment` will be overriden during the Docker image build process for stable-assistant
  // See stable-assistant's Dockerfile for more details
  environment: 'local',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 0.03 to capture 3%
  // of transactions for tracing.
  tracesSampleRate: 0.03,

  // Capture Replay for 3% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.03,
  replaysOnErrorSampleRate: 1.0
});
