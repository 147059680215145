import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';

import { RegularButton } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

type ToolExampleModalProps = {
  open: boolean;
  handleClose: () => void;
  inputs: any[];
};

export default function ToolExampleModal({
  open,
  handleClose,
  inputs
}: ToolExampleModalProps) {
  const exampleTitleInput = inputs.find(
    (input) => input.id === 'example-title'
  );
  const titleLabel = exampleTitleInput?.label || 'the Tool'; // TODO: eventually update with translator

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <DialogTitle>
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
          <Translator path="components.molecules.toolExampleModal.toolExampleTitle" />{' '}
          {` ${titleLabel}`}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: '20vw'
          }}
        >
          {inputs.map((input: any, index: number) => {
            // Render example titles
            if (input.id === 'example-title') {
              return (
                <Box key={index} sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">
                    {input.description}
                  </Typography>
                </Box>
              );
            }

            // Render images and descriptions
            if (input.id === 'example-image') {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%'
                  }}
                >
                  {/* Description */}
                  {input.description && (
                    <Typography variant="caption">
                      {input.description}
                    </Typography>
                  )}
                  {/* Use input.tooltip to format text in monospace (for prompts) */}
                  {input.tooltip && (
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: 'monospace', whiteSpace: 'pre-line' }}
                    >
                      {input.tooltip}
                    </Typography>
                  )}

                  {/* Image */}
                  {input.initial && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                    >
                      <img
                        src={input.initial}
                        alt={input.label}
                        style={{
                          marginTop: '8px',
                          maxWidth: '100%',
                          maxHeight: '300px',
                          borderRadius: '8px'
                        }}
                      />
                    </Box>
                  )}
                </Box>
              );
            }

            return null; // Skip any other inputs that don't match "example-title" or "example-image"
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <RegularButton onClick={handleClose}>
          <Translator path="components.molecules.toolExampleModal.backToTool" />
        </RegularButton>
      </DialogActions>
    </Dialog>
  );
}
