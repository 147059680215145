import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ImageThreadElement, sessionIdState } from '@chainlit/react-client';

import { apiClientState } from 'state/apiClient';
import { IAttachment } from 'state/chat';

export const useImageUrl = (
  image: ImageThreadElement | undefined | null
): string | undefined => {
  const sessionId = useRecoilValue(sessionIdState);
  const apiClient = useRecoilValue(apiClientState);

  const url = useMemo(() => {
    if (image?.elementId) {
      const params = new URLSearchParams({
        session_id: sessionId,
        thread_id: image.threadId
      });
      return apiClient.buildEndpoint(
        `/project/file/${image.elementId}?${params.toString()}`
      );
    }
  }, [sessionId, apiClient, image]);

  return url;
};

export const useAttachmentImageUrl = (
  attachment: IAttachment
): string | undefined => {
  if (!attachment.type.startsWith('image/')) {
    return;
  }

  return useImageUrl(
    attachment.serverId && attachment.threadId
      ? {
          elementId: attachment.serverId,
          threadId: attachment.threadId
        }
      : null
  );
};
