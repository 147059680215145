import { useRecoilValue } from 'recoil';

import SendButton from '@mui/icons-material/Send';
import StopCircle from '@mui/icons-material/StopCircle';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {
  styleSelectorModalState,
  useChatData,
  useChatInteract
} from '@chainlit/react-client';

import { Translator } from 'components/i18n';

interface SubmitButtonProps {
  disabled?: boolean;
  onSubmit: () => void;
}

const SubmitButton = ({ disabled, onSubmit }: SubmitButtonProps) => {
  const { loading } = useChatData();
  const { stopTask } = useChatInteract();
  const imageModalState = useRecoilValue(styleSelectorModalState);
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    stopTask();
  };

  return (
    <Box
      sx={{
        borderRadius: '0.5rem',
        backgroundColor:
          disabled || (imageModalState.selectedElement && !underSm)
            ? 'background.paper'
            : 'background.default',
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0.5rem',
          marginTop: '0.5rem'
        }
      }}
      mr={1}
    >
      {!loading ? (
        <Tooltip
          title={
            <Translator path="components.organisms.chat.inputBox.SubmitButton.sendMessage" />
          }
        >
          <IconButton
            id="submit-button"
            disabled={disabled}
            color="default"
            sx={{
              borderRadius: '0.5rem'
            }}
            onClick={onSubmit}
          >
            <SendButton />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            <Translator path="components.organisms.chat.inputBox.SubmitButton.stopTask" />
          }
        >
          <IconButton
            id="stop-button"
            color="default"
            sx={{
              borderRadius: '0.5rem'
            }}
            onClick={handleClick}
          >
            <StopCircle />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export { SubmitButton };
