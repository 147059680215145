import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Box } from '@mui/material';

import {
  productsState,
  sendEvent,
  subscriptionState,
  useChatInteract
} from '@chainlit/react-client';
import { AccentButton } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import SquarePenIcon from 'assets/squarePen';

export default function NewChatButton() {
  const navigate = useNavigate();
  const { createNewThread } = useChatInteract();
  const subscription = useRecoilValue(subscriptionState);
  const products = useRecoilValue(productsState);

  if (
    products !== null &&
    products !== undefined &&
    subscription !== null &&
    subscription?.credits <= 0
  ) {
    return null;
  }
  const handleConfirm = () => {
    createNewThread();
    navigate('/');
    sendEvent('new_chat');
  };

  return (
    <Box>
      <AccentButton
        id="new-chat-button"
        variant="outlined"
        onClick={handleConfirm}
        startIcon={<SquarePenIcon />}
      >
        <Translator path="components.molecules.newChatButton.newChat" />
      </AccentButton>
    </Box>
  );
}
