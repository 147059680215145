import { t } from 'i18next';
import { memo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Circle from '@mui/icons-material/Circle';
import PaletteIcon from '@mui/icons-material/Palette';
import { Box, IconButton, Tooltip } from '@mui/material';

import { styleSelectorModalState, useChatData } from '@chainlit/react-client';

import { projectSettingsState } from 'state/project';

interface Props {
  disabled?: boolean;
}

const StylePicker = memo(({ disabled }: Props) => {
  const [showPaletteRedCircle, setShowPaletteRedCircle] = useState(
    localStorage.getItem('showPaletteRedCircle') !== 'false'
  );
  const pSettings = useRecoilValue(projectSettingsState);
  const [_, setStyleModalState] = useRecoilState(styleSelectorModalState);
  const { elements } = useChatData();

  const sessionHasImageElements = elements.some(
    (element) => element.type === 'image'
  );

  if (!pSettings?.features.enable_style_picker) {
    return <></>;
  }

  return (
    <>
      <Tooltip
        title={
          sessionHasImageElements
            ? t('styleSelectorModal.openModalTooltip')
            : t('styleSelectorModal.disabledModalTooltip')
        }
        placement="bottom"
      >
        <Box>
          <IconButton
            id="style-picker"
            color="default"
            disabled={disabled}
            onClick={() => {
              setStyleModalState((state) => ({
                ...state,
                open: true
              }));
              setShowPaletteRedCircle(false);
              localStorage.setItem('showPaletteRedCircle', 'false');
            }}
          >
            <PaletteIcon />
            {showPaletteRedCircle && (
              <Circle
                sx={{
                  position: 'absolute',
                  transform: 'scale(0.5)',
                  top: 0,
                  right: 0,
                  color: '#ff0000'
                }}
              />
            )}
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
});

export default StylePicker;
