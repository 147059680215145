import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { accessTokenState } from '@chainlit/react-client';

import { apiClientState } from 'state/apiClient';

async function getLink(uuid: string, accessToken: string, apiClient: any) {
  const response = await apiClient.get(`/intern/link/telegram/${uuid}`, accessToken);
  const json_data = await response.json();
  window.location.href = json_data.url;
}

export default function Link() {
  const { uuid } = useParams();

  const accessToken = useRecoilValue(accessTokenState);
  const apiClient = useRecoilValue(apiClientState);

  useEffect(() => {
    if (!uuid || !accessToken || !apiClient) {
      return;
    }
    getLink(uuid, accessToken, apiClient);
  }, [accessToken, apiClient]);

  if (!uuid) {
    window.location.href = '/';
  }

  return null;
}
