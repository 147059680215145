import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { styleSelectorModalState } from '@chainlit/react-client';

import { useImageUrl } from 'hooks/image';

const StylePicker = memo(() => {
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down('sm'));
  const styleModalState = useRecoilValue(styleSelectorModalState);

  const url = useImageUrl(styleModalState.selectedElement);

  return url ? (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'absolute',
        height: '100%',
        top: 0,
        right: 0,
        overflow: 'hidden',
        borderRadius: 1
      }}
    >
      <img
        style={{
          height: underSm ? undefined : '20rem',
          width: underSm ? '100%' : undefined,
          maskImage: underSm
            ? 'linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.6) 60px, transparent 160px, transparent)'
            : 'linear-gradient(to left, rgba(0,0,0,0.8), rgba(0,0,0,0.5) 20%, transparent 90%, transparent)',
          userSelect: 'none',
          zIndex: 0
        }}
        draggable={false}
        src={url}
        alt="ChainLit"
      />
    </Stack>
  ) : null;
});

export default StylePicker;
