import { redirectToCheckout } from 'api';
import { useAuth } from 'api/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Stack } from '@mui/material';

import { AuthLogin } from '@chainlit/react-components';

import { Logo } from 'components/atoms/logo';

import { useQuery } from 'hooks/query';

import { apiClientState } from 'state/apiClient';

export default function Login() {
  const query = useQuery();
  const { data: config, setAccessToken, accessToken, user } = useAuth();
  const [error, setError] = useState(query.get('error'));
  const apiClient = useRecoilValue(apiClientState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {logout} = useAuth();

  const tier = query.get('tier');
  const interval = query.get('interval');
  const hasPricingRedirect = tier !== null && interval !== null;

  // Check if the user has visited this page multiple times in a row without reaching 
  // the chat. If so, log them out to prevent a redirect loop.
  const visitedLoginLast = localStorage.getItem('visited_login_last') || 'false';   
  localStorage.setItem('visited_login_last', 'true'); 

  const handleHeaderAuth = async () => {
    try {
      const json = await apiClient.headerAuth();
      setAccessToken(json.access_token);
      navigate('/');
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handlePasswordLogin = async (
    email: string,
    password: string,
    callbackUrl: string
  ) => {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    try {
      const json = await apiClient.passwordAuth(formData);
      setAccessToken(json.access_token);
      navigate(callbackUrl);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    setError(query.get('error') || '');
  }, [query]);

  async function doLogout() {
    window.location.href = await logout() || "/";
  }

  useEffect(() => {
    if (!config) {
      return;
    }
    if (hasPricingRedirect) {
      if (accessToken !== undefined && accessToken !== null) {
        redirectToCheckout(tier, interval, '', apiClient, accessToken);
        return;
      }
    }
    if (!config.requireLogin) {
      navigate('/');
    }
    if (config.headerAuth) {
      handleHeaderAuth();
    }
    if (user && !error){
      if (visitedLoginLast === 'true'){      
        // the user is logged in, and the user is visiting this page for a second time
        doLogout();
      }
    }
  }, [config, user, accessToken]);

  if (hasPricingRedirect && accessToken !== undefined && accessToken !== null) {
    return null;
  }

  if (user && !error){
    if (visitedLoginLast === 'true'){
      // user is being logged out
      return null
    }
  }

  return (
    <Stack>
      <AuthLogin
        title="Login to access the app."
        error={error || ''}
        callbackUrl="/"
        providers={config?.oauthProviders || []}
        onPasswordSignIn={
          config?.passwordAuth ? handlePasswordLogin : undefined
        }
        onOAuthSignIn={async (provider: string) => {
          window.location.href =
            apiClient.getOAuthEndpoint(provider) +
            (hasPricingRedirect ? `?tier=${tier}&interval=${interval}` : '');
        }}
        renderLogo={<Logo style={{ maxWidth: '60%', maxHeight: '90px' }} />}
        t={t}
      />
    </Stack>
  );
}
