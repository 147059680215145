import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import 'regenerator-runtime';

import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  IconButton,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import {
  appConditionsState,
  inputTextState,
  useChatData
} from '@chainlit/react-client';

import { Attachments } from 'components/molecules/attachments';
import AspectRatioButton from 'components/organisms/chat/aspectRatio';
import HistoryButton from 'components/organisms/chat/history';

import { IAttachment, attachmentsState } from 'state/chat';
import { chatSettingsOpenState, projectSettingsState } from 'state/project';
import { inputHistoryState } from 'state/userInputHistory';

import { SubmitButton } from './SubmitButton';
import UploadButton from './UploadButton';
import SpeechButton from './speechButton';
import StyleBackground from './styleBackground';
import StylePicker from './stylePicker';

interface Props {
  onFileUpload: (payload: File[]) => void;
  onFileUploadError: (error: string) => void;
  onSubmit: (message: string, attachments?: IAttachment[]) => void;
  onReply: (message: string) => void;
  onHistoryClick: (content: string) => void;
}

function getLineCount(el: HTMLDivElement) {
  const textarea = el.querySelector('textarea');
  if (!textarea) {
    return 0;
  }
  const lines = textarea.value.split('\n');
  return lines.length;
}

const Input = memo(
  ({
    onFileUpload,
    onFileUploadError,
    onSubmit,
    onReply,
    onHistoryClick
  }: Props) => {
    const ref = useRef<HTMLElement>();
    const [attachments, setAttachments] = useRecoilState(attachmentsState);
    const pSettings = useRecoilValue(projectSettingsState);
    const setInputHistory = useSetRecoilState(inputHistoryState);
    const setChatSettingsOpen = useSetRecoilState(chatSettingsOpenState);
    // Allow single attachment + text upload behind experimental flag for now
    const appConditions = useRecoilValue(appConditionsState);
    const theme = useTheme();
    const underSm = useMediaQuery(theme.breakpoints.down('sm'));

    const {
      loading,
      askUser,
      chatSettingsInputs,
      disabled: _disabled
    } = useChatData();

    const uploading = !!attachments.find((a) => !a.uploaded);
    const disabled = _disabled || uploading;

    const [value, setValue] = useRecoilState(inputTextState);
    const [isComposing, setIsComposing] = useState(false);
    const contextButtonsRef = useRef<HTMLDivElement>(null);

    const showTextToSpeech = pSettings?.features.speech_to_text?.enabled;

    const { t } = useTranslation();

    useEffect(() => {
      if (!appConditions.isExperimental && attachments.length > 0) {
        setValue('');
      }
    }, [attachments, appConditions.isExperimental]);

    useEffect(() => {
      if (!pSettings?.features?.multi_modal) {
        return;
      }
      const pasteEvent = (event: ClipboardEvent) => {
        if (event.clipboardData && event.clipboardData.items) {
          const items = Array.from(event.clipboardData.items);
          items.forEach((item) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              if (file) {
                onFileUpload([file]);
              }
            }
          });
        }
      };

      if (!ref.current) {
        return;
      }

      const input = ref.current;

      input.addEventListener('paste', pasteEvent);

      return () => {
        input.removeEventListener('paste', pasteEvent);
      };
    }, []);

    useEffect(() => {
      if (ref.current && !loading && !disabled) {
        ref.current.focus();
      }
    }, [loading, disabled]);

    const submit = useCallback(() => {
      if ((value === '' && attachments.length === 0) || disabled) {
        return;
      }
      if (askUser) {
        onReply(value);
      } else {
        onSubmit(value, attachments);
      }
      setAttachments([]);
      setValue('');
    }, [
      value,
      disabled,
      setValue,
      askUser,
      attachments,
      setAttachments,
      onSubmit
    ]);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          if (!isComposing) {
            e.preventDefault();
            submit();
          }
        } else if (e.key === 'ArrowUp') {
          const lineCount = getLineCount(e.currentTarget as HTMLDivElement);
          if (lineCount <= 1) {
            setInputHistory((old) => ({ ...old, open: true }));
          }
        }
      },
      [submit, setInputHistory, isComposing]
    );

    const textButtons = (
      <>
        {showTextToSpeech ? (
          <SpeechButton
            onSpeech={(transcript) => setValue((text) => text + transcript)}
            language={pSettings.features?.speech_to_text?.language}
            disabled={disabled}
          />
        ) : null}
        <UploadButton
          disabled={
            disabled ||
            (!appConditions.isExperimental && value !== '') ||
            attachments.length > 0
          }
          onFileUploadError={onFileUploadError}
          onFileUpload={onFileUpload}
        />
      </>
    );

    const startAdornment = <Stack direction={'row'}>{textButtons}</Stack>;

    const generationButtons = (
      <Stack
        direction={'row'}
        ref={contextButtonsRef}
        sx={{
          backgroundColor: 'background.paper',
          position: 'relative',
          borderRadius: '100vw',
          borderTopLeftRadius: '100vw',
          alignItems: 'center',
          marginRight: '0.5rem',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '0.5rem'
          }
        }}
      >
        <StylePicker disabled={disabled} />
        <Box mr={0.5} />
        <AspectRatioButton disabled={disabled} />
        {chatSettingsInputs.length > 0 && (
          <>
            <Box mr={0.5} />
            <IconButton
              id="chat-settings-open-modal"
              disabled={disabled}
              color="default"
              size="small"
              onClick={() => setChatSettingsOpen(true)}
            >
              <TuneIcon />
            </IconButton>
          </>
        )}
      </Stack>
    );

    const endAdornment = (
      <>
        <Stack
          direction={'row'}
          mt={0.5}
          sx={{
            alignItems: 'center',
            zIndex: 2,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              marginTop: 0
            }
          }}
        >
          {generationButtons}
          <SubmitButton
            onSubmit={submit}
            disabled={disabled || (!value && attachments.length === 0)}
          />
        </Stack>
      </>
    );

    return (
      <Stack
        direction={'row'}
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          boxShadow: 'box-shadow: 0px 2px 4px 0px #0000000D',
          width: '100%',
          position: 'relative',
          alignItems: 'stretch',
          textarea: {
            height: '34px',
            maxHeight: '30vh',
            overflowY: 'auto !important',
            resize: 'none',
            paddingBottom: '0.75rem',
            paddingTop: '0.75rem',
            color: 'text.primary',
            lineHeight: '24px'
          }
        }}
      >
        <Stack
          direction={'column'}
          sx={{
            flex: 1
          }}
        >
          {underSm || attachments.length > 0 ? (
            <Stack
              direction={'row'}
              sx={{
                pt: 1,
                px: 1,
                alignItems: 'center',
                zIndex: 1,
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem'
              }}
            >
              {underSm ? (
                <>
                  <StyleBackground />
                  <HistoryButton disabled={disabled} onClick={onHistoryClick} />
                  {textButtons}
                  <div style={{ marginRight: '0.5rem' }} />
                </>
              ) : null}
              {attachments.length > 0 ? <Attachments /> : null}
            </Stack>
          ) : null}

          {underSm ? undefined : <StyleBackground />}
          <TextField
            inputRef={ref}
            id="chat-input"
            autoFocus
            multiline
            variant="standard"
            autoComplete="false"
            placeholder={t(
              !appConditions.isExperimental && attachments.length > 0
                ? uploading
                  ? 'components.organisms.chat.inputBox.input.placeholderWhileUploading'
                  : 'components.organisms.chat.inputBox.input.placeholderWhenAttached'
                : 'components.organisms.chat.inputBox.input.placeholder'
            )}
            sx={{
              flex: 1,
              '&.Mui-disabled':
                !appConditions.isExperimental && attachments.length > 0
                  ? {
                      '&::placeholder': {
                        opacity: 0.9,
                        WebkitTextFillColor: (theme: any) =>
                          theme.palette.primary.main
                      }
                    }
                  : {},
              [theme.breakpoints.down('sm')]: {
                maxHeight: '8rem',
                overflowY: 'auto'
              }
            }}
            disabled={
              appConditions.isExperimental
                ? _disabled
                : disabled ||
                  attachments.length >
                    0 /* We allow either single text or single file attachment */
            }
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onCompositionStart={() => setIsComposing(true)}
            onCompositionEnd={() => setIsComposing(false)}
            value={value}
            fullWidth
            InputProps={{
              style: {
                display: 'flex',
                alignItems: 'flex-start',
                zIndex: 1
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  sx={{ ml: 1, color: 'text.secondary', height: '100%', mt: 1 }}
                  position="start"
                >
                  {underSm ? undefined : startAdornment}
                </InputAdornment>
              ),
              endAdornment: underSm ? undefined : endAdornment
            }}
          />
        </Stack>
        {underSm ? endAdornment : undefined}
      </Stack>
    );
  }
);

export default Input;
