import { atom } from 'recoil';

export const defaultImageViewerState = {
  open: false,
  url: '',
  type: ''
};

export const imageViewerState = atom<{
  open: boolean;
  url: string | undefined;
  type: string | undefined;
}>({
  key: 'ImageViewerSettings',
  default: defaultImageViewerState
});
