// makes for really smooth animations, which we can use for panning, etc
// https://nolanlawson.com/2019/08/11/high-performance-input-handling-on-the-web/
export function throttleRAF() {
  let queuedCallback: (() => void) | null = null;
  return (callback: typeof queuedCallback) => {
    if (!queuedCallback) {
      requestAnimationFrame(() => {
        const cb = queuedCallback;
        queuedCallback = null;
        cb?.();
      });
    }
    queuedCallback = callback;
  };
}
