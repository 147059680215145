import { useTranslation } from 'react-i18next';

import { Box, Stack, useTheme } from '@mui/material';
import { Typography } from '@mui/material';

interface WatermarkProps {
  disclaimerText: string;
}

// TODO: rename as disclaimer
export default function WaterMark({ disclaimerText }: WatermarkProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        mx="auto"
        p={0.5}
        px={1.5}
        sx={{
          backgroundColor: 'background.paper'
        }}
      >
        {disclaimerText.length > 0 && (
          <Stack mx="auto" sx={{ paddingBottom: '5px' }}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="0.75rem"
            >
              {disclaimerText}
            </Typography>
          </Stack>
        )}
        <Stack mx="auto" className="watermark" direction="row" spacing={2}>
          <a
            href={t(
              'components.organisms.chat.inputBox.disclaimer.privatePolicyLink'
            )}
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontSize: '0.75rem'
            }}
          >
            {t(
              'components.organisms.chat.inputBox.disclaimer.privatePolicyText'
            )}
          </a>

          <a
            href={t(
              'components.organisms.chat.inputBox.disclaimer.termsOfUseLink'
            )}
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontSize: '0.75rem'
            }}
          >
            {t('components.organisms.chat.inputBox.disclaimer.termsOfUseText')}
          </a>

          <a
            href={t(
              'components.organisms.chat.inputBox.disclaimer.acceptableUseLink'
            )}
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontSize: '0.75rem'
            }}
          >
            {t(
              'components.organisms.chat.inputBox.disclaimer.acceptableUseText'
            )}
          </a>
        </Stack>
      </Stack>
    </Box>
  );
}
