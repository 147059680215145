import { toast } from 'sonner';

import {
  APIBase,
  ChainlitAPI,
  ClientError,
  sendEvent
} from '@chainlit/react-client';

const devServer = 'http://localhost:8000';
const url = import.meta.env.DEV ? devServer : window.origin;
const serverUrl = new URL(url);

const httpEndpoint = `${serverUrl.protocol}//${serverUrl.host}`;

const on401 = () => {
  if (window.location.pathname !== '/login') {
    // The credentials aren't correct, remove the token and redirect to login
    window.location.href = '/landing';
  }
};

const onError = (error: ClientError) => {
  toast.error(error.toString());
};

export const apiClient = new ChainlitAPI(httpEndpoint, 'app', on401, onError);

export const redirectToCheckout = async (
  tier: string,
  interval: string,
  sessionId: string,
  apiClient: APIBase,
  accessToken: string | undefined
) => {
  const res = await apiClient.post(
    `/checkout/${tier}/${interval}?session_id=${sessionId}`,
    {},
    accessToken
  );
  sendEvent(`checkout_${tier}_${interval}`);
  if (res.status !== 200) {
    // TODO: display error?
    return;
  }
  const data = await res.json();
  window.location.href = data.url;
};
