import { useTranslation } from 'react-i18next';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';

const ZOOM_LEVELS = [
  { label: '15%', value: 0.15 },
  { label: '25%', value: 0.25 },
  { label: '50%', value: 0.5 },
  { label: '75%', value: 0.75 },
  { label: '100%', value: 1 },
  { label: '125%', value: 1.25 },
  { label: '150%', value: 1.5 },
  { label: '200%', value: 2 },
  { label: '400%', value: 4 }
];

export function getNextZoomLevel(zoom: number): number {
  const currentLevel = ZOOM_LEVELS.findIndex(({ value }) => value === zoom);
  if (currentLevel === -1) return zoom;
  return ZOOM_LEVELS[currentLevel + 1]?.value ?? zoom;
}

export function getPrevZoomLevel(zoom: number): number {
  const currentLevel = ZOOM_LEVELS.findIndex(({ value }) => value === zoom);
  if (currentLevel === -1) return zoom;
  return ZOOM_LEVELS[currentLevel - 1]?.value ?? zoom;
}

export function getZoomLevel(
  imageWidth: number,
  imageHeight: number,
  chromeWidth = 0,
  chromeHeight = 0
): number {
  const relativeWidth = (window.innerWidth - chromeWidth) / imageWidth;
  const relativeHeight = (window.innerHeight - chromeHeight) / imageHeight;

  const minDimension = Math.min(relativeWidth, relativeHeight);
  if (minDimension < 0.2) {
    return 0.15;
  } else if (minDimension < 0.6) {
    return 0.25;
  } else if (minDimension < 0.8) {
    return 0.5;
  } else if (minDimension < 1.25) {
    return 0.75;
  } else {
    return 1;
  }
}

type CanvasZoomProps = {
  zoom: number;
  setZoom: (zoom: number) => void;
};

export const CanvasZoom = ({ zoom, setZoom }: CanvasZoomProps) => {
  const { t } = useTranslation();
  const onChangeZoom = (e: SelectChangeEvent) => {
    setZoom(parseFloat(e.target.value));
  };
  return (
    <FormControl
      sx={{
        width: 100,
        position: 'absolute',
        left: { xs: '1em', sm: 'auto' },
        bottom: { xs: '1em', sm: 'auto' },
        top: { xs: 'auto', sm: '1.5em' },
        right: { xs: 'auto', sm: '2em' }
      }}
    >
      <InputLabel id="zoom-selector-label">
        {t('components.molecules.zoomOutOverlay.labels.zoom')}
      </InputLabel>
      <Select
        size={'small'}
        labelId="zoom-selector-label"
        value={String(zoom)}
        onChange={onChangeZoom}
        label={t('components.molecules.zoomOutOverlay.labels.zoom')}
      >
        {ZOOM_LEVELS.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
