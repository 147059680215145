import { apiClient } from 'api';
import { redirectToCheckout } from 'api';
import { useAuth } from 'api/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { accessTokenState, subscriptionState } from '@chainlit/react-client';

import { useQuery } from 'hooks/query';

export default function AuthCallback() {
  const query = useQuery();
  const { user, setAccessToken } = useAuth();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useRecoilState(subscriptionState);
  const pricingTier = query.get('tier');
  const pricingInterval = query.get('interval');
  const accessToken = useRecoilValue(accessTokenState);

  const fetchSubscription = async (accessToken: string | null) => {
    const res = await apiClient.post(`/subscription`, {}, accessToken || '');

    const data = await res.json();

    if (data === null) {
      // If the user has no subscription, set the subscription to undefined to indicate we have fetched it
      setSubscription({ tier: null, credits: -1, name: '', color: '' });
    }

    setSubscription({
      tier: data.tier,
      credits: data.credits,
      name: data.name,
      color: data.color
    });
  };

  useEffect(() => {
    const token = query.get('access_token');
    setAccessToken(token);
    fetchSubscription(token);
  }, [query]);

  useEffect(() => {
    if (user === null || accessToken === undefined) {
      // user is not authed
      return;
    }

    // user is authed, handle redirects
    if (!pricingTier || !pricingInterval) {
      // if this is not a redirect, just go to the chat
      navigate('/');
      return;
    }

    if (subscription === null || subscription === undefined) {
      // subscription is not loaded yet, wait
      return;
    }

    if (subscription.tier === null) {
      // not subscribed, go to stripe
      redirectToCheckout(
        pricingTier,
        pricingInterval,
        '',
        apiClient,
        query.get('access_token') || ''
      );
    } else {
      // user is already subscribed, go to chat
      navigate('/');
    }
  }, [user, subscription, accessToken]);

  return null;
}
