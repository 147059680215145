import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Close } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { UploadContext } from '@chainlit/react-client';
import {
  AccentButton,
  IFormInput,
  InputStateHandler
} from '@chainlit/react-components';

import { Translator } from 'components/i18n';
import {
  getMediaSelectorState,
  useMediaSelectorImageSelector
} from 'components/molecules/mediaSelector';

import { useImageUrl } from 'hooks/image';

interface MediaSelectorOwnProps {
  uploadContext: UploadContext;
  buttonLabel: string;
}

export type MediaSelectorProps = MediaSelectorOwnProps &
  IFormInput<'media', string>;

const MediaSelectorComponent = ({
  description,
  hasError,
  id,
  label,
  buttonLabel,
  tooltip,
  uploadContext,
  setField
}: MediaSelectorProps) => {
  const [state, setState] = useRecoilState(
    getMediaSelectorState(uploadContext)
  );
  const url = useImageUrl(state.selectedElement);
  const onSelectImage = useMediaSelectorImageSelector(uploadContext);

  useEffect(() => {
    if (setField) {
      setField(id, JSON.stringify(state.selectedElement) ?? '');
    }
  }, [state.selectedElement]);

  const openModal = () => {
    setState((s) => ({ ...s, open: true }));
  };

  const clearSelection = () => {
    setState((s) => ({ ...s, selectedElement: null }));
    onSelectImage(null);
  };

  return (
    <InputStateHandler
      description={description}
      hasError={hasError}
      label={label}
      id={id}
      tooltip={tooltip}
    >
      <Box px={0.25}>
        {url ? (
          <Box sx={{ display: 'inline-block', position: 'relative' }} m={1}>
            <img src={url} style={{ maxHeight: '15rem', maxWidth: '100%' }} />
            <Tooltip
              title={
                <Translator path="components.molecules.attachments.removeAttachment" />
              }
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  p: 0.5,
                  right: -12,
                  top: -10,
                  background: 'white',
                  backgroundColor: 'background.paper',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    backgroundColor: 'background.default'
                  }
                }}
                onClick={clearSelection}
              >
                <Close
                  sx={(theme) => ({
                    height: 16,
                    width: 16,
                    [theme.breakpoints.down('sm')]: {
                      height: 12,
                      width: 12
                    }
                  })}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <AccentButton variant="outlined" onClick={openModal}>
            {buttonLabel}
          </AccentButton>
        )}
      </Box>
    </InputStateHandler>
  );
};

export default MediaSelectorComponent;
