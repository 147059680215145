import { ImageThreadElement } from '@chainlit/react-client';

import { IAttachment } from 'state/chat';

const getImageId = (
  item: IAttachment | ImageThreadElement | null | undefined
): string | undefined => {
  return item
    ? (item as ImageThreadElement).elementId || (item as IAttachment).serverId
    : undefined;
};

export const imageElementsMatch = (
  itemA: IAttachment | ImageThreadElement | null | undefined,
  itemB: IAttachment | ImageThreadElement | null | undefined
) => {
  return getImageId(itemA) === getImageId(itemB);
};
