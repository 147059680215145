/* eslint-disable @typescript-eslint/ban-ts-comment*/
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import HistoryIcon from '@mui/icons-material/History';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  SvgIconProps,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';

import {
  IMessageElement,
  advancedRerollState,
  recentToolsState,
  sendEvent,
  toolModalState,
  useChatInteract
} from '@chainlit/react-client';
import { toolsState } from '@chainlit/react-client';
import { useIsDarkMode } from '@chainlit/react-components';

import { Translator } from 'components/i18n';
import RedDot from 'components/molecules/redDot';

import {
  toolHistory,
  toolboxClickedState,
  userImageToolHistory
} from './state';

// TODO: later refactor by getting this value from BE
// in integrity/moderation.py#L16
const MAX_RECENT_TOOLS = 3;

// TODO: put this in its own file
function BatchToolboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="230"
        height="230"
        viewBox="40 40 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 167.005L24 173.123L115.113 205.34L206.245 173.123L188.937 167.005L115.113 193.103L41.304 167.005Z"
          fill="inherit"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 145.566L24 151.685L115.113 183.902L206.245 151.685L188.937 145.566L115.113 171.664L41.304 145.566Z"
          fill="inherit"
          fill-opacity="0.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 124.127L24 130.246L115.113 162.462L206.245 130.246L188.937 124.127L115.113 150.225L41.304 124.127Z"
          fill="inherit"
          fill-opacity="0.2"
        />
        <path
          d="M149.623 94.5212H138.036V88.7277H91.6883V94.5212H80.1014V88.7277H56.9277V117.695H172.796V88.7277H149.623V94.5212ZM161.209 48.1737H143.829V36.5869C143.829 30.2141 138.615 25 132.242 25H97.4817C91.109 25 85.8949 30.2141 85.8949 36.5869V48.1737H68.5146C62.1418 48.1737 56.9277 53.3878 56.9277 59.7606V82.9343H80.1014V71.3474H91.6883V82.9343H138.036V71.3474H149.623V82.9343H172.796V59.7606C172.796 53.3878 167.582 48.1737 161.209 48.1737ZM132.242 48.1737H97.4817V36.5869H132.242V48.1737Z"
          fill="inherit"
        />
      </svg>
    </SvgIcon>
  );
}

interface Props {
  instance: string;
  selectedElementType: string;
  selectedElementIds: string[];
  selectedElementUrl: string;
  forStepId: string;
  configureAdvancedReroll: boolean;
  selectedElementInfoForTools?: string;
  isUser?: boolean;
  buttonStyle?: object;
  iconStyle?: object;
  tooltip?: string;
}

interface AdvancedRerollProps {
  selectedElementType: string;
  selectedElementId: string;
  forStepId: string;
  onAdvancedRerollAction?: (element_id: string, step_id: string) => void;
}

interface MultiAdvancedRerollProps {
  selectedElementType: string;
  selectedElements: IMessageElement[];
  onMultiAdvancedRerollAction?: (elements: IMessageElement[]) => void;
}

type Tool = { title: string; description: string };

// anything here will get the red dot treatment
const newTools = new Set([
  'Inpaint',
  'Erase',
  'ImageTo3D',
  'SearchAndRecolor',
  'Outpaint'
]);

// red dot for new things, but only if the image is user uploaded
const newUserImageTools = new Set(['ImageToVideo']);

// if we want to re-red-dot an existing tool we can modify its storage key
const toolKeyMap = new Map<string, string>([
  ['Outpaint', 'Outpaint-new-ux-sep2024']
]);

// TODO: put this in its own file
function InitialAdvancedRerollIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.03 8.29091V12.6655L23.045 6.83273L17.03 1V5.37455C10.3834 5.37455 5 10.5948 5 17.04C5 19.3293 5.69172 21.4583 6.86465 23.2519L9.06012 21.1229C8.38344 19.9126 8.0075 18.5128 8.0075 17.04C8.0075 12.2134 12.0526 8.29091 17.03 8.29091ZM27.1953 10.8281L24.9999 12.9571C25.6615 14.182 26.0525 15.5672 26.0525 17.04C26.0525 21.8666 22.0074 25.7891 17.03 25.7891V21.4145L11.015 27.2473L17.03 33.08V28.7055C23.6766 28.7055 29.06 23.4852 29.06 17.04C29.06 14.7507 28.3683 12.6217 27.1953 10.8281Z"
          fill="inherit"
          fill-opacity="0.5"
        />
        <path
          d="M19.9364 21.9617L18.6046 18.4118L15.0547 17.08L18.6046 15.7482L19.9364 12.1982L21.2682 15.7482L24.8182 17.08L21.2682 18.4118L19.9364 21.9617Z"
          fill="inherit"
        />
        <path
          d="M12.6899 17.3799L11.9428 15.4371L10 14.6899L11.9428 13.9428L12.6899 12L13.4371 13.9428L15.3799 14.6899L13.4371 15.4371L12.6899 17.3799Z"
          fill="inherit"
        />
        <path
          d="M12.6748 22.3858L12.2446 21.2096L11.0684 20.7793L12.2446 20.3491L12.6748 19.1729L13.1051 20.3491L14.2813 20.7793L13.1051 21.2096L12.6748 22.3858Z"
          fill="inherit"
        />
      </svg>
    </SvgIcon>
  );
}

// TODO: put this in its own file
function ReApplyAdvancedRerollIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.03 8.29091V12.6655L23.045 6.83273L17.03 1V5.37455C10.3834 5.37455 5 10.5948 5 17.04C5 19.3293 5.69172 21.4583 6.86465 23.2519L9.06012 21.1229C8.38344 19.9126 8.0075 18.5128 8.0075 17.04C8.0075 12.2134 12.0526 8.29091 17.03 8.29091ZM27.1953 10.8281L24.9999 12.9571C25.6615 14.182 26.0525 15.5672 26.0525 17.04C26.0525 21.8666 22.0074 25.7891 17.03 25.7891V21.4145L11.015 27.2473L17.03 33.08V28.7055C23.6766 28.7055 29.06 23.4852 29.06 17.04C29.06 14.7507 28.3683 12.6217 27.1953 10.8281Z"
          fill="inherit"
        />
        <path
          d="M19.9364 21.9617L18.6046 18.4118L15.0547 17.08L18.6046 15.7482L19.9364 12.1982L21.2682 15.7482L24.8182 17.08L21.2682 18.4118L19.9364 21.9617Z"
          fill="inherit"
          fill-opacity="0.8"
        />
        <path
          d="M12.6899 17.3799L11.9428 15.4371L10 14.6899L11.9428 13.9428L12.6899 12L13.4371 13.9428L15.3799 14.6899L13.4371 15.4371L12.6899 17.3799Z"
          fill="inherit"
          fill-opacity="0.65"
        />
        <path
          d="M12.6748 22.3858L12.2446 21.2096L11.0684 20.7793L12.2446 20.3491L12.6748 19.1729L13.1051 20.3491L14.2813 20.7793L13.1051 21.2096L12.6748 22.3858Z"
          fill="inherit"
          fill-opacity="0.65"
        />
      </svg>
    </SvgIcon>
  );
}

function InitialMultiAdvancedRerollIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="230"
        height="230"
        viewBox="20 20 190 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M115.053 45.123V62.5968L139.079 39.2984L115.053 16V33.4738C88.5037 33.4738 67 54.3259 67 80.0707C67 89.2153 69.763 97.7192 74.4482 104.883L83.2179 96.3796C80.5149 91.5451 79.0132 85.9535 79.0132 80.0707C79.0132 60.7912 95.1711 45.123 115.053 45.123ZM155.658 55.2578L146.888 63.7618C149.531 68.6544 151.093 74.1878 151.093 80.0707C151.093 99.3501 134.935 115.018 115.053 115.018V97.5445L91.0265 120.843L115.053 144.141V126.668C141.602 126.668 163.106 105.815 163.106 80.0707C163.106 70.926 160.343 62.4221 155.658 55.2578Z"
          fill="inherit"
          fill-opacity="0.5"
        />
        <path
          d="M127.589 99.5694L122.27 85.3896L108.09 80.0699L122.27 74.7502L127.589 60.5703L132.909 74.7502L147.089 80.0699L132.909 85.3896L127.589 99.5694Z"
          fill="inherit"
        />
        <path
          d="M98.6431 81.2667L95.6586 73.5065L87.8984 70.522L95.6586 67.5375L98.6431 59.7773L101.628 67.5375L109.388 70.522L101.628 73.5065L98.6431 81.2667Z"
          fill="inherit"
        />
        <path
          d="M98.583 101.263L96.8642 96.5644L92.166 94.8457L96.8642 93.1269L98.583 88.4287L100.302 93.1269L105 94.8457L100.302 96.5644L98.583 101.263Z"
          fill="inherit"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 176.029L24 182.148L115.113 214.364L206.245 182.148L188.937 176.029L115.113 202.127L41.304 176.029Z"
          fill="inherit"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 154.59L24 160.708L115.113 192.925L206.245 160.708L188.937 154.59L115.113 180.688L41.304 154.59Z"
          fill="inherit"
          fill-opacity="0.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 133.15L24 139.269L115.113 171.486L206.245 139.269L188.937 133.15L115.113 159.248L41.304 133.15Z"
          fill="inherit"
          fill-opacity="0.2"
        />
      </svg>
    </SvgIcon>
  );
}

// TODO: put this in its own file
function ReApplyMultiAdvancedRerollIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="230"
        height="230"
        viewBox="20 20 190 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M115.053 45.123V62.5968L139.079 39.2984L115.053 16V33.4738C88.5037 33.4738 67 54.3259 67 80.0707C67 89.2153 69.763 97.7192 74.4482 104.883L83.2179 96.3796C80.5149 91.5451 79.0132 85.9535 79.0132 80.0707C79.0132 60.7912 95.1711 45.123 115.053 45.123ZM155.658 55.2578L146.888 63.7618C149.531 68.6544 151.093 74.1878 151.093 80.0707C151.093 99.3501 134.935 115.018 115.053 115.018V97.5445L91.0265 120.843L115.053 144.141V126.668C141.602 126.668 163.106 105.815 163.106 80.0707C163.106 70.926 160.343 62.4221 155.658 55.2578Z"
          fill="inherit"
        />
        <path
          d="M127.589 99.5694L122.27 85.3896L108.09 80.0699L122.27 74.7502L127.589 60.5703L132.909 74.7502L147.089 80.0699L132.909 85.3896L127.589 99.5694Z"
          fill="inherit"
          fill-opacity="0.8"
        />
        <path
          d="M98.6431 81.2667L95.6586 73.5065L87.8984 70.522L95.6586 67.5375L98.6431 59.7773L101.628 67.5375L109.388 70.522L101.628 73.5065L98.6431 81.2667Z"
          fill="inherit"
          fill-opacity="0.65"
        />
        <path
          d="M98.583 101.263L96.8642 96.5644L92.166 94.8457L96.8642 93.1269L98.583 88.4287L100.302 93.1269L105 94.8457L100.302 96.5644L98.583 101.263Z"
          fill="inherit"
          fill-opacity="0.65"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 176.029L24 182.148L115.113 214.364L206.245 182.148L188.937 176.029L115.113 202.127L41.304 176.029Z"
          fill="inherit"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 154.59L24 160.708L115.113 192.925L206.245 160.708L188.937 154.59L115.113 180.688L41.304 154.59Z"
          fill="inherit"
          fill-opacity="0.5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.304 133.15L24 139.269L115.113 171.486L206.245 139.269L188.937 133.15L115.113 159.248L41.304 133.15Z"
          fill="inherit"
          fill-opacity="0.2"
        />
      </svg>
    </SvgIcon>
  );
}

export function ExecuteAdvancedRerollButton({
  selectedElementType,
  selectedElementId,
  forStepId,
  onAdvancedRerollAction
}: AdvancedRerollProps) {
  const { t } = useTranslation();
  const isDarkMode = useIsDarkMode();
  const advRerollState = useRecoilValue(advancedRerollState);
  const tools =
    useRecoilValue<Record<string, Record<string, Tool>>>(toolsState);

  let tool_name = advRerollState?.toolName;
  if (tool_name && tool_name in tools[selectedElementType]) {
    tool_name = tools[selectedElementType][tool_name].title;
  }

  const tooltip = t('multiReroll.advancedRerollApplyTooltip') + tool_name;

  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <div>
        <ReApplyAdvancedRerollIcon
          onClick={() => {
            onAdvancedRerollAction &&
              onAdvancedRerollAction(selectedElementId, forStepId);
          }}
          fontSize="large"
          style={{ fill: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)' }}
        />
      </div>
    </Tooltip>
  );
}

export function ExecuteMultiAdvancedRerollButton({
  selectedElementType,
  selectedElements,
  onMultiAdvancedRerollAction
}: MultiAdvancedRerollProps) {
  const { t } = useTranslation();
  const advRerollState = useRecoilValue(advancedRerollState);
  const tools =
    useRecoilValue<Record<string, Record<string, Tool>>>(toolsState);

  let tool_name = advRerollState?.toolName;
  if (tool_name && tool_name in tools[selectedElementType]) {
    tool_name = tools[selectedElementType][tool_name].title;
  }

  const tooltip = t('multiReroll.advancedRerollMultiApplyTooltip') + tool_name;

  return (
    <Tooltip title={tooltip} placement="right" arrow>
      <div>
        <ReApplyMultiAdvancedRerollIcon
          onClick={() => {
            onMultiAdvancedRerollAction &&
              onMultiAdvancedRerollAction(selectedElements);
          }}
          fontSize="medium"
          style={{ fill: '#a5945d' }}
        />
      </div>
    </Tooltip>
  );
}

function getRecentTools(
  recentTools: Record<string, string[]>
): [string[], string[]] {
  return [recentTools['tools'] || [], recentTools['advanced'] || []];
}

export function ToolListButton({
  instance,
  selectedElementType,
  selectedElementIds,
  selectedElementUrl,
  forStepId,
  configureAdvancedReroll,
  selectedElementInfoForTools,
  isUser,
  buttonStyle,
  iconStyle
}: Props) {
  const { checkToolValidity } = useChatInteract();
  const advRerollState = useRecoilValue(advancedRerollState);
  const tools =
    useRecoilValue<Record<string, Record<string, Tool>>>(toolsState);
  const recentTools =
    useRecoilValue<Record<string, string[]>>(recentToolsState);

  const setToolModalState = useSetRecoilState(toolModalState);
  const [isToolListOpen, setToolsListOpen] = useState(false);
  const isDarkMode = useIsDarkMode();
  const backgroundColor = isDarkMode
    ? 'rgba(0, 0, 0, 0.5)'
    : 'rgba(255, 255, 255, 0.5)';
  const hoveredBackgroundColor = isDarkMode
    ? 'rgba(0, 0, 0, 0.7)'
    : 'rgba(255, 255, 255, 0.7)';
  const ref = useRef<any>();

  // TODO: clean up this anchor ref stuff, we should be able to do this declaratively
  const [anchorEl, setAnchorEl] = useState<typeof ref.current>(null);
  const { t } = useTranslation();
  const isBatchButton = buttonStyle !== undefined;

  if (isToolListOpen && !anchorEl) {
    if (ref.current) {
      setAnchorEl(ref.current);
    }
  }

  const toggleToolListOpen = (open: boolean) => {
    setToolsListOpen(open);
    if (!open) {
      sendEvent('toolbox_used');
      setAnchorEl(null);
    }
  };

  const [hasUsedToolbox, setHasUsedToolbox] =
    useRecoilState(toolboxClickedState);
  const [usedTools, setUsedTools] = useRecoilState(toolHistory);
  const [usedUserImageTools, setUsedUserImageTools] =
    useRecoilState(userImageToolHistory);

  if (!tools || !selectedElementType || !(selectedElementType in tools)) {
    return null;
  }

  // seed the global red dot variable, but also update it if we have unseen tools
  let hasUnseenTools = !hasUsedToolbox;

  // Rebuild tool list with recent tools on top
  const [recentToolsList, advancedRecentToolsList] =
    getRecentTools(recentTools);
  const highlightTools = configureAdvancedReroll
    ? advancedRecentToolsList
    : recentToolsList;

  function sortToolsByLatest(
    allTools: [string, Tool][],
    highlightTools: string[]
  ): [string, Tool][] {
    allTools = allTools.filter(([toolName, tool]: any) => {
      return checkToolValidity(
        toolName,
        tool,
        selectedElementType,
        configureAdvancedReroll,
        selectedElementInfoForTools !== undefined
          ? selectedElementInfoForTools
          : '{}'
      );
    });

    const topTools: [string, Tool][] = [];
    highlightTools.map((toolName) => {
      const elems = allTools.filter(([name, _tool]) => name === toolName);
      elems.forEach((elem) => topTools.push(elem));
    });
    const otherTools = allTools.filter(
      ([name]) => !highlightTools.includes(name)
    );
    return [...topTools, ...otherTools];
  }

  const sortedTools = sortToolsByLatest(
    Object.entries(tools[selectedElementType]),
    highlightTools
  );

  const menuEls: React.ReactNode[] = [];

  // Prepend a "Recently used" title section before rendering the tools
  const showRecentTools =
    highlightTools.length > 0 && sortedTools.length > MAX_RECENT_TOOLS;

  function renderRecentToolsTitle(showRecentTools: boolean): React.ReactNode {
    if (!showRecentTools) return null;

    return (
      <Box
        key="recent-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
          pt: 1,
          borderRadius: '4px 4px 0 0',
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2)
        }}
      >
        <Typography variant="subtitle2" color="text.primary">
          <Translator path="components.molecules.toolMenu.recentlyUsedTitle" />
        </Typography>
        <HistoryIcon fontSize="small" sx={{ color: 'text.secondary' }} />
      </Box>
    );
  }

  const recentToolsTitle = renderRecentToolsTitle(showRecentTools);
  menuEls.unshift(recentToolsTitle);

  sortedTools.forEach(([toolName, tool]) => {
    const toolKey = toolKeyMap.has(toolName)
      ? (toolKeyMap.get(toolName) as string)
      : toolName;

    // check for both used tools and user image used tools
    const isNewTool =
      (newTools.has(toolName) && !usedTools.includes(toolKey)) ||
      (isUser &&
        newUserImageTools.has(toolName) &&
        !usedUserImageTools.includes(toolKey));

    // set the unseen tools variable if we encounter an unseen tool
    if (isNewTool) {
      // TODO: differentiate per menu
      hasUnseenTools = true;
    }

    // Check if the tool is in the recent tools list
    const isRecentTool = highlightTools.includes(toolName);
    const isLastRecentTool =
      isRecentTool && highlightTools[highlightTools.length - 1] === toolName;

    const radius = isLastRecentTool ? '0 0 4px 4px' : isRecentTool ? 0 : '4px'; // Rounded bottom corners for the last recent tool

    menuEls.push(
      <MenuItem
        className="tool-item"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleToolListOpen(false);
          const updateTools = (tools: string[]) => {
            if (!tools.includes(toolKey)) {
              return tools.concat(toolKey);
            }
            return tools;
          };
          setUsedTools(updateTools);
          if (isUser) {
            setUsedUserImageTools(updateTools);
          }
          setToolModalState({
            modality: selectedElementType,
            tool: toolName,
            selectedElementIds: selectedElementIds,
            selectedElementUrl: selectedElementUrl,
            forStepId: forStepId,
            open: true,
            selectedElementInfoForTools: selectedElementInfoForTools,
            isConfiguringAdvancedReroll: configureAdvancedReroll
          });
        }}
        disableRipple
        key={tool.title}
        sx={{
          p: 1,
          alignItems: 'baseline',
          borderRadius: radius,
          position: 'relative', // Required for positioning the "recently used" label
          backgroundColor:
            showRecentTools && isRecentTool
              ? (theme) => alpha(theme.palette.primary.main, 0.2)
              : 'inherit',
          ...(showRecentTools &&
            isRecentTool && {
              '&:hover': {
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.4)
              }
            })
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            color="text.primary"
            sx={{
              whiteSpace: 'pre-wrap',
              fontSize: '14px',
              maxHeight: '50px',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              flexGrow: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: '24px'
            }}
          >
            {tool.title}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {tool.description}
          </Typography>
          {isNewTool && <RedDot top={10} right={10} />}
        </div>
      </MenuItem>
    );
  });

  const menu = anchorEl ? (
    <Menu
      autoFocus
      anchorEl={anchorEl}
      open={isToolListOpen}
      onClose={() => toggleToolListOpen(false)}
      PaperProps={{
        sx: {
          p: 1,
          backgroundImage: 'none',
          mt: -2,
          ml: -1,
          overflow: 'visible',
          maxHeight: '314px',
          width: '334px',
          overflowY: 'auto',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          boxShadow: (theme) =>
            theme.palette.mode === 'light'
              ? '0px 2px 4px 0px #0000000D'
              : '0px 10px 10px 0px #0000000D'
        }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {menuEls}
    </Menu>
  ) : null;

  const advancedRerollToolSelected = !(
    !advRerollState || !advRerollState.toolName
  );

  const advancedRerollTooltip = advancedRerollToolSelected
    ? t('multiReroll.advancedRerollChangeSettingsTooltip')
    : isBatchButton
    ? t('multiReroll.advancedRerollMultiInitialTooltip')
    : t('multiReroll.advancedRerollInitialTooltip');

  const toolboxTooltip =
    selectedElementIds.length > 1
      ? t('multiReroll.toollistTooltip')
      : 'Open toolbox';

  return (
    <div ref={ref}>
      {menu}
      <Tooltip
        title={configureAdvancedReroll ? advancedRerollTooltip : toolboxTooltip}
        placement={isBatchButton ? 'right' : 'top'}
        arrow
      >
        <div>
          {!configureAdvancedReroll && (
            <IconButton
              key={instance}
              sx={
                buttonStyle === undefined
                  ? {
                      backgroundColor: backgroundColor,
                      '&:hover': {
                        backgroundColor: hoveredBackgroundColor
                      }
                    }
                  : buttonStyle
              }
              onClick={() => {
                setHasUsedToolbox(true);
                toggleToolListOpen(!isToolListOpen);
              }}
            >
              {!isBatchButton && (
                <HomeRepairServiceIcon
                  fontSize={iconStyle === undefined ? 'large' : undefined}
                  sx={iconStyle || {}}
                />
              )}
              {isBatchButton && (
                <BatchToolboxIcon
                  fontSize={iconStyle === undefined ? 'large' : undefined}
                  sx={iconStyle || {}}
                />
              )}
              {hasUnseenTools && <RedDot />}
            </IconButton>
          )}
          {configureAdvancedReroll && advancedRerollToolSelected && (
            <MoreVertIcon
              fontSize={iconStyle === undefined ? 'large' : undefined}
              sx={iconStyle || {}}
              onClick={() => {
                toggleToolListOpen(!isToolListOpen);
              }}
            />
          )}
          {configureAdvancedReroll &&
            !advancedRerollToolSelected &&
            !isBatchButton && (
              <InitialAdvancedRerollIcon
                fontSize="large"
                style={{ fill: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)' }}
                sx={iconStyle || {}}
                onClick={() => {
                  toggleToolListOpen(!isToolListOpen);
                }}
              />
            )}
          {configureAdvancedReroll &&
            !advancedRerollToolSelected &&
            isBatchButton && (
              <InitialMultiAdvancedRerollIcon
                fontSize="medium"
                style={{ fill: '#a5945d' }}
                sx={iconStyle || {}}
                onClick={() => {
                  toggleToolListOpen(!isToolListOpen);
                }}
              />
            )}
        </div>
      </Tooltip>
    </div>
  );
}
