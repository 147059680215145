import { useRecoilState } from 'recoil';

import { Dialog, DialogContent } from '@mui/material';

import { imageViewerState } from 'state/imageViewer';

export default function ImageModal() {
  const [settings, setSettings] = useRecoilState(imageViewerState);

  return (
    <Dialog
      open={settings.open}
      onClose={() => setSettings((old) => ({ ...old, open: false }))}
      id="settings-dialog"
      PaperProps={{
        sx: {
          backgroundImage: 'none',
          maxWidth: '125%'
        }
      }}
    >
      <DialogContent>
        {settings.type == 'image' && (
          <img
            src={settings.url}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        )}
        {settings.type == 'video' && (
          <video
            src={settings.url}
            autoPlay
            loop
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
