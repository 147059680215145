import { FormEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'sonner';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import { useChatInteract } from '@chainlit/react-client';
import { AccentButton, RegularButton } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import {
  generationShareModalState,
  shareCheckboxState
} from 'state/generationShareModal';
import { projectSettingsState } from 'state/project';

import { InternalDebugMarker } from '../atoms/InternalDebugMarker';

// these are types that can be shared in the gallery
const galleryMimeTypes = new Set(['image', 'video']);

interface ShareFormControls extends HTMLFormControlsCollection {
  admin_shared?: HTMLInputElement;
  public_shared?: HTMLInputElement;
}

export default function GenerationShareModal() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useRecoilState(generationShareModalState);
  const { share, unshare } = useChatInteract();

  const [tooltipText, setTooltipText] = useState('Click to copy!');
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const url = window.location.origin + `/share/${modalState.sharedUuid}`;

  const handleOpenInNewTab = async () => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleShare = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const elements = e.currentTarget.elements as ShareFormControls;
    const promise = share(modalState.elementId!, modalState.type!, {
      adminShared: elements.admin_shared?.checked || false,
      publicShared: elements.public_shared?.checked || false
    });
    if (!promise) return;
    try {
      const response = await promise;
      setModalState((old) => ({ ...old, sharedUuid: response.uuid }));
    } catch (error) {
      toast.error('Failed to share element');
    }
  };

  const handleUnshare = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const promise = unshare(modalState.elementId!, modalState.sharedUuid!);
    if (!promise) return;
    try {
      await promise;
      setModalState((old) => ({ ...old, sharedUuid: undefined }));
    } catch (error) {
      toast.error('Failed to unshare element');
    }
  };

  if (modalState?.sharedUuid == 'blocked') {
    return (
      <Dialog
        open={modalState.open}
        onClose={() => {
          setModalState((old) => ({ ...old, open: false }));
        }}
        id="settings-dialog"
        PaperProps={{
          sx: {
            backgroundImage: 'none',
            width: '125%'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Translator path="shareModal.blockedTitle" />
        </DialogTitle>
        <DialogContent>
          <Fragment>{t('shareModal.blockedContent')}</Fragment>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <RegularButton
            onClick={() => setModalState((old) => ({ ...old, open: false }))}
          >
            <Translator path="shareModal.close" />
          </RegularButton>
        </DialogActions>
      </Dialog>
    );
  }

  const [shareCheckbox, setShareCheckbox] = useRecoilState(shareCheckboxState);

  const settings = useRecoilValue(projectSettingsState);
  if (settings == null) {
    return null;
  }

  const { allow_public_share, allow_admin_share } = settings.features;

  const type = modalState.type?.split('/')[0] || 'unknown';
  const isPubliclyShareableType = galleryMimeTypes.has(type);

  return (
    <Dialog
      open={modalState.open}
      onClose={() => {
        setModalState((old) => ({ ...old, open: false }));
        setTooltipText('Click to copy!');
      }}
      id="settings-dialog"
      PaperProps={{
        sx: {
          backgroundImage: 'none',
          width: '125%'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Translator path="shareModal.Title" />
      </DialogTitle>
      <DialogContent>
        <Fragment>
          {!modalState.sharedUuid
            ? t('shareModal.hidden')
            : t('shareModal.visible')}
        </Fragment>

        {modalState.sharedUuid && (
          <Fragment>
            <Tooltip arrow title={tooltipText}>
              <Typography
                variant="body1"
                sx={{
                  '& code': {
                    color: theme.palette.primary.dark, // Change this to your desired color
                    bgcolor: isDarkMode ? '#202020' : '#e0e0e0', // Change this to your desired background color
                    padding: '2px 4px', // Optional: Add some padding
                    borderRadius: '4px' // Optional: Add border radius
                  }
                }}
                style={{ cursor: 'pointer', marginTop: '10px' }}
                component={'div'}
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  setTooltipText('Copied!');
                }}
              >
                <code>{url}</code>
              </Typography>
            </Tooltip>
          </Fragment>
        )}
      </DialogContent>
      <form onSubmit={!modalState.sharedUuid ? handleShare : handleUnshare}>
        <DialogActions sx={{ p: 2, alignItems: 'end' }}>
          <Stack
            flex="1 0 0"
            direction="column"
            sx={{ position: 'relative', fontSize: '.9em' }}
          >
            {!modalState.sharedUuid && isPubliclyShareableType && (
              <>
                {allow_public_share && (
                  <label>
                    <Checkbox
                      name="public_shared"
                      checked={shareCheckbox.public}
                      onChange={(_, checked) => {
                        setShareCheckbox((val) => ({
                          public: checked,
                          admin: val.admin
                        }));
                      }}
                    />{' '}
                    {t('shareModal.addToGallery')}
                  </label>
                )}
                {allow_admin_share && (
                  <label>
                    <Checkbox
                      name="admin_shared"
                      checked={shareCheckbox.admin}
                      onChange={(_, checked) => {
                        setShareCheckbox((val) => ({
                          admin: checked,
                          public: val.public
                        }));
                      }}
                    />{' '}
                    <InternalDebugMarker /> {t('shareModal.shareAsAdmin')}
                  </label>
                )}
              </>
            )}
          </Stack>
          {modalState.sharedUuid && (
            <RegularButton type="button" onClick={() => handleOpenInNewTab()}>
              <Translator path="shareModal.openInNewTab" />
            </RegularButton>
          )}

          <AccentButton id="confirm" variant="outlined" type="submit" autoFocus>
            {!modalState.sharedUuid && <Translator path="shareModal.share" />}
            {modalState.sharedUuid && <Translator path="shareModal.unshare" />}
          </AccentButton>
          <RegularButton
            onClick={() => {
              setModalState((old) => ({ ...old, open: false }));
              setTooltipText('Click to copy!');
            }}
          >
            <Translator path="shareModal.close" />
          </RegularButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
