import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import {
  generationExampleModalState,
  inputTextState
} from '@chainlit/react-client';
import {
  AccentButton,
  RegularButton,
  useIsDarkMode
} from '@chainlit/react-components';

export default function GenerationExampleModal() {
  const [exampleState, setExampleState] = useRecoilState(
    generationExampleModalState
  );
  const setInputText = useSetRecoilState(inputTextState);
  const isDarkMode = useIsDarkMode();

  const handleCopy = () => {
    setInputText(exampleState.inputText);
    handleClose();
  };

  const handleClose = () => {
    setExampleState((prev) => {
      return {
        ...prev,
        open: false
      };
    });
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={exampleState.open}
      PaperProps={{
        sx: {
          backgroundImage: 'none'
        }
      }}
    >
      <DialogTitle>{exampleState.title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            minWidth: '20vw',
            maxHeight: '70vh'
          }}
        >
          <div style={{ fontWeight: 'bold' }}>Input Text:</div>
          <div>{exampleState.inputText}</div>
          {exampleState.type === 'image' && (
            <>
              <div style={{ fontWeight: 'bold' }}>Generated Image:</div>
              <img
                src={exampleState.result}
                style={{
                  height: 'auto',
                  width: '100%'
                }}
              />
            </>
          )}
          {exampleState.type === 'audio' && (
            <>
              {/* TODO: Implement */}
              <div style={{ fontWeight: 'bold' }}>Generated Audio:</div>
              <div
                style={{
                  backgroundColor: isDarkMode ? '#7A7468' : '#E5DFC8',
                  width: '100%',
                  clipPath:
                    'polygon(26px 0, 100% 0, 100% 100%, 0 100%, 0 26px)',
                  justifyContent: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <svg
                  width="124"
                  height="62"
                  viewBox="0 0 124 62"
                  fill="none"
                  className="relative z-20 h-1/2 w-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ padding: 10, width: '100%' }}
                >
                  <path
                    opacity="0.4"
                    d="M124 62H0C0 27.7583 27.7583 0 62 0C96.2417 0 124 27.7583 124 62Z"
                    fill="url(#paint0_linear_2429_59287)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2429_59287"
                      x1="62"
                      y1="0"
                      x2="62"
                      y2="68.4138"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.9999" stopColor="white" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <audio
                  src={exampleState.result}
                  controls
                  style={{
                    width: '100%'
                  }}
                />
              </div>
            </>
          )}
          {exampleState.type === 'video' && (
            <>
              <div style={{ fontWeight: 'bold' }}>Generated Video:</div>
              <video
                width="100%"
                autoPlay
                loop
                src={exampleState.result}
                style={{
                  height: 'auto',
                  width: '100%'
                }}
              />
            </>
          )}
          {exampleState.type === 'model' && (
            <>
              <div style={{ fontWeight: 'bold' }}>Generated 3D Model:</div>
              <model-viewer
                ar
                camera-controls
                loading="eager"
                max-camera-orbit="auto auto 300%"
                orientation="0 0 180deg"
                src={exampleState.result}
                style={{
                  aspectRatio: '1',
                  backgroundColor: isDarkMode ? '#505050' : '#E8E8E8',
                  height: 'auto',
                  margin: 'auto',
                  objectFit: 'cover',
                  width: '100%'
                }}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <div style={{ flex: '1 0 0' }} />
        <RegularButton onClick={handleClose}>Close</RegularButton>
        <AccentButton variant="outlined" onClick={handleCopy} autoFocus>
          Try it !
        </AccentButton>
      </DialogActions>
    </Dialog>
  );
}
