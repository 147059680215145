import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

import { Alert, Box } from '@mui/material';

import {
  threadHistoryState,
  useChatData,
  useChatInteract
} from '@chainlit/react-client';
import { ErrorBoundary, useUpload } from '@chainlit/react-components';

import SideView from 'components/atoms/element/sideView';
import { Translator } from 'components/i18n';
import ChatProfiles from 'components/molecules/chatProfiles';
import { TaskList } from 'components/molecules/tasklist/TaskList';

import { useQuery } from 'hooks/query';

import { apiClientState } from 'state/apiClient';
import { IAttachment, attachmentsState, fileSpecState } from 'state/chat';
import { projectSettingsState, sideViewState } from 'state/project';

import Messages from './Messages';
import DropScreen from './dropScreen';
import InputBox from './inputBox';

const Chat = () => {
  const projectSettings = useRecoilValue(projectSettingsState);
  const [attachments, setAttachments] = useRecoilState(attachmentsState);
  const setThreads = useSetRecoilState(threadHistoryState);
  const sideViewElement = useRecoilValue(sideViewState);
  const apiClient = useRecoilValue(apiClientState);

  const [autoScroll, setAutoScroll] = useState(true);
  const [hideSubscribeAlert, setHideSubscribeAlert] = useState(false);
  const { error, disabled } = useChatData();
  const { uploadFile } = useChatInteract();
  const uploadFileRef = useRef(uploadFile);
  const query = useQuery();

  const fileSpec = useRecoilValue(fileSpecState);

  const { t } = useTranslation();

  const subscribed = 'success' === query.get('result');
  const discordSubscribed = 'success_discord' === query.get('result');
  const successLinkTelegram = 'success_link_telegram' === query.get('result');
  const failedLinkTelegram = 'failed_link_telegram' === query.get('result');

  const alertEventJustHappened =
    subscribed ||
    discordSubscribed ||
    successLinkTelegram ||
    failedLinkTelegram;
  const hasAttachmentRef = useRef(false);

  useEffect(() => {
    if (alertEventJustHappened) {
      setTimeout(() => {
        setHideSubscribeAlert(true);
      }, 60000);
    }
  }, []);

  useEffect(() => {
    uploadFileRef.current = uploadFile;
  }, [uploadFile]);

  useEffect(() => {
    hasAttachmentRef.current = attachments.length > 0;
  }, [attachments]);

  const onFileUpload = useCallback(
    (payloads: File[]) => {
      // We allow single file upload
      if (hasAttachmentRef.current || payloads.length > 1) {
        // TODO: Show error message to user?
        return;
      }
      const newAttachments: IAttachment[] = payloads.map((file) => {
        const id = uuidv4();

        const { xhr, promise } = uploadFileRef.current(
          apiClient,
          file,
          'message',
          (progress) => {
            setAttachments((prev) =>
              prev.map((attachment) => {
                if (attachment.id === id) {
                  return {
                    ...attachment,
                    // Hack: Cap the progress at 95% until getting a response.
                    //       Otherwise user might be confused by seeing not done for a while
                    //       after reaching 100% if validation in the server takes time.
                    uploadProgress: Math.min(progress, 95)
                  };
                }
                return attachment;
              })
            );
          }
        );

        toast.promise(promise, {
          loading: `${t('components.organisms.chat.index.uploading')} ${
            file.name
          }`,
          success: `${t('components.organisms.chat.index.uploaded')} ${
            file.name
          }`,
          error: (error) => {
            // Ugh...
            // TODO: Remove the magic keyword "Aborted"
            // TODO: Any way to show the info icon for canceling?
            if (error.message === 'Aborted') {
              return `${t(
                'components.organisms.chat.index.cancelledUploadOf'
              )} ${file.name}`;
            }
            return `${t('components.organisms.chat.index.failedToUpload')} ${
              file.name
            }: ${error.message}`;
          }
        });

        promise
          .then((res) => {
            setAttachments((prev) =>
              prev.map((attachment) => {
                if (attachment.id === id) {
                  return {
                    ...attachment,
                    // Update with the server ID
                    serverId: res.id,
                    threadId: res.threadId,
                    uploaded: true,
                    uploadProgress: 100,
                    elementInfoForTools: res.elementInfoForTools,
                    cancel: undefined
                  };
                }
                return attachment;
              })
            );
          })
          .catch(() => {
            setAttachments((prev) =>
              prev.filter((attachment) => attachment.id !== id)
            );
          });

        return {
          id,
          type: file.type,
          name: file.name,
          size: file.size,
          uploadProgress: 0,
          cancel: () => {
            xhr.abort();
            setAttachments((prev) =>
              prev.filter((attachment) => attachment.id !== id)
            );
          },
          remove: () => {
            setAttachments((prev) =>
              prev.filter((attachment) => attachment.id !== id)
            );
          }
        };
      });
      setAttachments((prev) => prev.concat(newAttachments));
    },
    [uploadFile, attachments]
  );

  const onFileUploadError = useCallback(
    () => (error: string) => toast.error(error),
    []
  );

  const upload = useUpload({
    spec: fileSpec,
    onResolved: onFileUpload,
    onError: onFileUploadError,
    options: { noClick: true, multiple: false }
  });

  useEffect(() => {
    setThreads((prev) => ({
      ...prev,
      currentThreadId: undefined
    }));
  }, []);

  const enableMultiModalUpload =
    !disabled && projectSettings?.features?.multi_modal;

  return (
    <Box
      {...(enableMultiModalUpload
        ? upload?.getRootProps({ className: 'dropzone' })
        : {})}
      // Disable the onFocus and onBlur events in react-dropzone to avoid interfering with child trigger events
      onBlur={undefined}
      onFocus={undefined}
      display="flex"
      width="100%"
      flexGrow={1}
      position="relative"
    >
      {upload ? (
        <>
          <input id="#upload-drop-input" {...upload.getInputProps()} />
          {upload?.isDragActive ? <DropScreen /> : null}
        </>
      ) : null}
      <SideView>
        <Box my={1} />
        {alertEventJustHappened && !hideSubscribeAlert && (
          <Box
            sx={{
              width: '100%',
              maxWidth: '60rem',
              mx: 'auto',
              my: 2
            }}
          >
            <Alert
              sx={{ mx: 2 }}
              id="session-success"
              severity={failedLinkTelegram ? 'error' : 'success'}
            >
              {discordSubscribed && (
                <div>
                  {t('components.organisms.chat.index.justSubscribedDiscord')}
                  <a href={t('components.organisms.chat.index.discordLink')}>
                    {t('components.organisms.chat.index.discordLinkTitle')}
                  </a>
                </div>
              )}
              {subscribed && (
                <Translator path="components.organisms.chat.index.justSubscribed" />
              )}
              {successLinkTelegram && (
                <Translator path="components.organisms.chat.index.justLinkedTelegram" />
              )}
              {failedLinkTelegram && (
                <Translator path="components.organisms.chat.index.failLinkedTelegram" />
              )}
            </Alert>
          </Box>
        )}
        {error ? (
          <Box
            sx={{
              width: '100%',
              maxWidth: '60rem',
              mx: 'auto',
              my: 2
            }}
          >
            <Alert sx={{ mx: 2 }} id="session-error" severity="error">
              <Translator path="components.organisms.chat.index.couldNotReachServer" />
            </Alert>
          </Box>
        ) : null}
        <TaskList isMobile={true} />
        <ErrorBoundary>
          <ChatProfiles />
          <Messages
            autoScroll={autoScroll}
            projectSettings={projectSettings}
            setAutoScroll={setAutoScroll}
          />
          <InputBox
            onFileUpload={onFileUpload}
            onFileUploadError={onFileUploadError}
            autoScroll={autoScroll}
            setAutoScroll={setAutoScroll}
            projectSettings={projectSettings}
          />
        </ErrorBoundary>
      </SideView>
      {sideViewElement ? null : <TaskList isMobile={false} />}
    </Box>
  );
};

export default Chat;
