import { atom } from 'recoil';

type ThemeVariant = 'dark' | 'light';

const defaultTheme =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

const preferredTheme = localStorage.getItem(
  'themeVariant'
) as ThemeVariant | null;

const preferredChatHistoryOptedOut = localStorage.getItem(
  'chatHistoryOptedOut'
) as string | null;

const language =
  (localStorage.getItem('selectedLanguage') as string | null) || '';

const theme = preferredTheme ? preferredTheme : defaultTheme;
const chatHistoryOptedOut = preferredChatHistoryOptedOut
  ? preferredChatHistoryOptedOut === 'true'
  : false;

export const defaultSettingsState = {
  open: false,
  defaultCollapseContent: true,
  expandAll: false,
  hideCot: false,
  chatHistoryOptedOut: chatHistoryOptedOut,
  isChatHistoryOpen: true,
  language,
  theme
};

export const settingsState = atom<{
  open: boolean;
  defaultCollapseContent: boolean;
  expandAll: boolean;
  hideCot: boolean;
  theme: ThemeVariant;
  chatHistoryOptedOut: boolean;
  isChatHistoryOpen: boolean;
  language: string;
}>({
  key: 'AppSettings',
  default: defaultSettingsState
});
