import { redirectToCheckout } from 'api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';

import {
  ProductProps,
  SubscriptionProps,
  accessTokenState,
  productsState,
  sendEvent,
  sessionIdState,
  subscriptionState
} from '@chainlit/react-client';
import { Markdown, useIsDarkMode } from '@chainlit/react-components';

import { useQuery } from 'hooks/query';

import { apiClientState } from 'state/apiClient';

interface ProductDisplayProps {
  product: ProductProps;
  displayInterval: 'month' | 'year';
  purchasedTier: SubscriptionProps | null | undefined;
}

export const Product = ({
  product,
  displayInterval,
  purchasedTier
}: ProductDisplayProps) => {
  const apiClient = useRecoilValue(apiClientState);
  const accessToken = useRecoilValue(accessTokenState);
  const sessionId = useRecoilValue(sessionIdState);
  const isDarkMode = useIsDarkMode();
  const { t } = useTranslation();

  const fetchManagementUrl = async () => {
    const res = await apiClient.post(`/manage`, {}, accessToken);
    sendEvent(`manage_subscription`);
    if (res.status !== 200) {
      // TODO: display error?
      return;
    }
    const data = await res.json();
    window.location.href = data.url;
  };

  const isPurchased = purchasedTier?.tier === product.tier;

  return (
    <TableContainer
      component={Paper}
      sx={{
        m: 2,
        width: 250,
        alignItems: 'center',
        display: 'flex',
        bgcolor: isDarkMode ? 'background.paper' : '#f8f0f0'
      }}
      elevation={0}
    >
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                backgroundColor: product.color,
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{ color: 'text.primary', fontSize: '200%' }}
              >
                {product.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ justifyContent: 'center', display: 'flex' }}>
              <Stack direction="column">
                <Stack direction={'row'}>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ color: 'text.primary' }}
                  >
                    {' '}
                    {displayInterval == 'month'
                      ? product.monthly_price
                      : product.yearly_price}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: 'text.primary', mt: '16px' }}
                  >
                    {' '}
                    {displayInterval == 'month'
                      ? t('pages.pricing.perMonth')
                      : t('pages.pricing.perYear')}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{ color: 'text.secondary', fontSize: 14 }}
                >
                  {t('pages.pricing.taxDisclaimer')}
                </Typography>

                <Box sx={{ my: 1 }}></Box>
                {product.features.map((feature, index) => (
                  <Box key={index} sx={{ display: 'flex' }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontSize="180%"
                      sx={{ mr: '3px', color: product.color }}
                    >
                      ✔
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontSize="100%"
                      fontWeight={'bold'}
                      sx={{ mt: '8px' }}
                    >
                      {feature.description}
                    </Typography>
                    {index < product.features.length - 1 && (
                      <Box sx={{ my: 1 }} />
                    )}
                  </Box>
                ))}
                <Box sx={{ my: 1 }}></Box>
                {(purchasedTier?.tier === null ||
                  purchasedTier?.tier == 'voided_trial') && (
                  <>
                    <Button
                      onClick={() =>
                        redirectToCheckout(
                          product.tier,
                          displayInterval,
                          sessionId,
                          apiClient,
                          accessToken
                        )
                      }
                      sx={{
                        borderColor: product.color,
                        bgcolor: product.color,
                        '&:hover': {
                          borderColor: product.color,
                          bgcolor: product.color,
                          opacity: 0.7
                        }
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={isDarkMode ? 'text.primary' : '#FFFFFF'}
                        fontWeight={'bold'}
                      >
                        {t('pages.pricing.subscribe')}
                      </Typography>
                    </Button>
                  </>
                )}

                {isPurchased && (
                  <>
                    <Button
                      onClick={() => fetchManagementUrl()}
                      sx={{
                        borderColor: product.color,
                        bgcolor: product.color,
                        '&:hover': {
                          borderColor: product.color,
                          bgcolor: product.color,
                          opacity: 0.7
                        }
                      }}
                    >
                      <Typography
                        variant="body1"
                        color={isDarkMode ? 'text.primary' : '#FFFFFF'}
                        fontWeight={'bold'}
                      >
                        {t('pages.pricing.manage')}
                      </Typography>
                    </Button>
                  </>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function Pricing() {
  const accessToken = useRecoilValue(accessTokenState);
  const products = useRecoilValue(productsState);
  const subscription = useRecoilValue(subscriptionState);
  const [displayInterval, setDisplayInterval] = useState<'month' | 'year'>(
    'month'
  );
  const navigate = useNavigate();
  const isDarkMode = useIsDarkMode();
  const { t } = useTranslation();

  const query = useQuery();

  const error = query.get('error');

  useEffect(() => {
    if (accessToken !== undefined && products === undefined) {
      navigate('/');
    }
  }, [accessToken, products]);

  if (products === null || products === undefined) {
    return null;
  }

  const isLoading = products === null;
  return (
    <Container
      sx={{
        scrollbarWidth: 'thin',
        scrollbarColor: 'darkgrey transparent'
      }}
    >
      {error && (
        <Box
          sx={{
            width: '100%',
            maxWidth: '60rem',
            mx: 'auto',
            my: 2
          }}
        >
          <Alert sx={{ mx: 2 }} id="session-error" severity="error">
            {t(`pages.pricing.error.${error}`)}
          </Alert>
        </Box>
      )}
      <Typography
        variant="h4"
        color="text.primary"
        component="h1"
        gutterBottom
        align="center"
        sx={{ marginY: 2 }}
      >
        {t('pages.pricing.plansTitle')}
      </Typography>
      <Box display="flex" justifyContent="center">
        <ToggleButtonGroup
          value={displayInterval}
          exclusive
          onChange={(event, value: string | null) => {
            setDisplayInterval(value == 'month' ? 'month' : 'year');
          }}
          aria-label="text alignment"
          sx={{
            bgcolor: isDarkMode ? 'background.paper' : '#f8f0f0',
            mb: 5,
            mt: 3
          }}
        >
          <ToggleButton
            value="month"
            aria-label="left aligned"
            sx={{ maxWidth: '200px', minWidth: '120px' }}
          >
            {t('pages.pricing.monthly')}
          </ToggleButton>
          <ToggleButton
            value="year"
            aria-label="centered"
            sx={{
              position: 'relative',
              minWidth: '120px',
              maxWidth: '200px'
            }}
          >
            {t('pages.pricing.yearly')}
            {
              <Chip
                label={t('pages.pricing.discount')}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  backgroundColor: 'primary.main',
                  padding: '0 8px',
                  '& .MuiChip-label': {
                    overflow: 'visible'
                  },
                  transform: 'translate(55%, -60%)',
                  fontSize: '0.8rem',
                  maxWidth: 120
                }}
              ></Chip>
            }
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '50px'
          }}
        >
          {' '}
          <CircularProgress color="info" />
        </div>
      )}
      {!isLoading && (
        <Grid container justifyContent="center">
          {products.map((product, index) => (
            <Grid item key={index}>
              <Product
                product={product}
                displayInterval={displayInterval}
                purchasedTier={subscription}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Box sx={{ minHeight: '70px' }} />
      {t('pages.pricing.informationContent') !==
        'pages.pricing.informationContent' && (
        <>
          <Typography
            variant="h4"
            color="text.primary"
            component="h1"
            gutterBottom
            align="center"
            sx={{ marginY: 2 }}
          >
            {t('pages.pricing.informationTitle')}
          </Typography>
          <Box sx={{ color: 'text.primary' }}>
            <Markdown>{t('pages.pricing.informationContent')}</Markdown>
          </Box>
        </>
      )}
    </Container>
  );
}
