import { useAuth } from 'api/auth';
import { t } from 'i18next';
import { memo, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  IStep,
  inputTextState,
  useChatData,
  useChatInteract
} from '@chainlit/react-client';

import ScrollDownButton from 'components/atoms/buttons/scrollDownButton';
import HistoryButton from 'components/organisms/chat/history';

import { IAttachment, attachmentsState } from 'state/chat';
import { IProjectSettings } from 'state/project';
import { inputHistoryState } from 'state/userInputHistory';

import Input from './input';
import WaterMark from './waterMark';

interface Props {
  onFileUpload: (payload: File[]) => void;
  onFileUploadError: (error: string) => void;
  setAutoScroll: (autoScroll: boolean) => void;
  autoScroll?: boolean;
  projectSettings?: IProjectSettings;
}

const InputBox = memo(
  ({
    onFileUpload,
    onFileUploadError,
    setAutoScroll,
    autoScroll,
    projectSettings
  }: Props) => {
    const setInputHistory = useSetRecoilState(inputHistoryState);
    const [_, setInputValue] = useRecoilState(inputTextState);
    const [attachments, __] = useRecoilState(attachmentsState);

    const theme = useTheme();
    const underSm = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useAuth();
    const { sendMessage, replyMessage } = useChatInteract();
    const { disabled: _disabled } = useChatData();
    const uploading = !!attachments.find((a) => !a.uploaded);
    const disabled = _disabled || uploading;

    // const tokenCount = useRecoilValue(tokenCountState);

    const onSubmit = useCallback(
      async (msg: string, attachments?: IAttachment[]) => {
        let elementInfoForTools = undefined;
        if (attachments !== undefined && attachments.length > 0) {
          elementInfoForTools = attachments[0].elementInfoForTools;
        }

        const message: IStep = {
          threadId: '',
          id: uuidv4(),
          name: user?.identifier || 'User',
          type: 'user_message',
          output: msg,
          createdAt: new Date().toISOString(),
          elementInfoForTools: elementInfoForTools
        };
        setInputHistory((old) => {
          const MAX_SIZE = 50;
          const inputs = [...(old.inputs || [])];
          inputs.push({
            content: msg,
            createdAt: new Date().getTime()
          });

          return {
            ...old,
            inputs:
              inputs.length > MAX_SIZE
                ? inputs.slice(inputs.length - MAX_SIZE)
                : inputs
          };
        });

        const fileReferences = attachments
          ?.filter((a) => !!a.serverId)
          .map((a) => ({
            id: a.serverId!,
            elementInfoForTools: a.elementInfoForTools
          }));

        setAutoScroll(true);
        sendMessage(message, fileReferences);
      },
      [user, projectSettings, sendMessage]
    );

    const onReply = useCallback(
      async (msg: string) => {
        const message: IStep = {
          threadId: '',
          id: uuidv4(),
          name: user?.identifier || 'User',
          type: 'user_message',
          output: msg,
          createdAt: new Date().toISOString()
        };

        replyMessage(message);
        setAutoScroll(true);
      },
      [user, replyMessage]
    );

    return (
      <Box>
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          gap={1}
          p={2}
          sx={{
            boxSizing: 'border-box',
            width: '100%',
            maxWidth: '70rem',
            m: 'auto',
            justifyContent: 'center'
          }}
        >
          {!autoScroll ? (
            <ScrollDownButton onClick={() => setAutoScroll(true)} />
          ) : null}
          <Stack direction={'row'} alignItems={'center'}>
            {underSm ? null : (
              <HistoryButton disabled={disabled} onClick={setInputValue} />
            )}
            <Input
              onFileUpload={onFileUpload}
              onFileUploadError={onFileUploadError}
              onSubmit={onSubmit}
              onReply={onReply}
              onHistoryClick={setInputValue}
            />
            {/* {tokenCount > 0 && ( */}
            {/* <Stack flexDirection="row" alignItems="center">
            <Typography
              sx={{ ml: 'auto' }}
              color="text.secondary"
              variant="caption"
            >
              Token usage: {tokenCount}
            </Typography>
          </Stack> */}
            {/* )} */}
          </Stack>
        </Box>
        <WaterMark
          disclaimerText={t(
            'components.organisms.chat.inputBox.disclaimer.disclaimerText'
          )}
        />
      </Box>
    );
  }
);

export default InputBox;
