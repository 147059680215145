import { Navigate, createBrowserRouter } from 'react-router-dom';

import AuthCallback from 'pages/AuthCallback';
import Element from 'pages/Element';
import Env from 'pages/Env';
import Home from 'pages/Home';
import Link from 'pages/Link';
import Login from 'pages/Login';
import Page from 'pages/Page';
import Pricing from 'pages/Pricing';
import Readme from 'pages/Readme';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/readme',
    element: <Readme />
  },
  {
    path: '/pricing',
    element: (
      <Page>
        <Pricing />
      </Page>
    )
  },
  {
    path: '/env',
    element: <Env />
  },
  {
    path: '/thread/:id?',
    element: <Home />
  },
  {
    path: '/element/:id',
    element: <Element />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/login/callback',
    element: <AuthCallback />
  },
  {
    path: '/link_telegram/:uuid',
    element: <Link />
  },
  {
    path: '*',
    element: <Navigate replace to="/" />
  }
]);
