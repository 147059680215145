import { apiClient } from 'api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ExpandIcon from '@mui/icons-material/Expand';
import LanguageIcon from '@mui/icons-material/Language';
import SpeakerNotesOffOutlined from '@mui/icons-material/SpeakerNotesOffOutlined';
import {
  Box,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  sendEvent,
  sessionState,
  useApi,
  useChatInteract
} from '@chainlit/react-client';
import { SwitchInput } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import { projectSettingsState } from 'state/project';
import { settingsState } from 'state/settings';

import LanguageSelector from './languageSelector';

export default function SettingsModal() {
  const projectSettings = useRecoilValue(projectSettingsState);
  const session = useRecoilValue(sessionState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const { t } = useTranslation();
  const { createNewThread, updateClientSettings } = useChatInteract();
  const [prevSessionId, setPrevSessionId] = useState('');
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);

  const { data: languagesData } = useApi<string[]>(
    apiClient,
    '/project/translations'
  );

  useEffect(() => {
    if (languagesData) {
      setAvailableLanguages(languagesData);
    }
  }, [languagesData]);

  const enableLanguageSelector =
    availableLanguages.filter((lang) => lang !== 'en-US').length > 0;

  useEffect(() => {
    createNewThread();
    localStorage.setItem(
      'chatHistoryOptedOut',
      settings.chatHistoryOptedOut.toString()
    );
  }, [settings.chatHistoryOptedOut]);

  useEffect(() => {
    // When a new conversation starts, inform the server of the new settings
    if (session !== undefined && session?.socket.id != prevSessionId) {
      updateClientSettings(settings);
      setPrevSessionId(session?.socket.id);
    }
  }, [session, session?.socket.id]);

  return (
    <Dialog
      open={settings.open}
      onClose={() => setSettings((old) => ({ ...old, open: false }))}
      id="settings-dialog"
      PaperProps={{
        sx: {
          backgroundImage: 'none'
        }
      }}
    >
      <DialogContent>
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          subheader={
            <ListSubheader>
              Settings
              <Tooltip
                title={t('components.molecules.settingsModal.tooltip')}
                arrow
              >
                <Typography
                  component="span"
                  variant="body1"
                  style={{ marginLeft: 8, cursor: 'help', color: 'gray' }}
                >
                  ?
                </Typography>
              </Tooltip>
            </ListSubheader>
          }
        >
          <ListItem sx={{ display: 'flex', gap: 2 }}>
            <ListItemIcon>
              <ExpandIcon />
            </ListItemIcon>
            <ListItemText
              id="list-expand-all"
              primary={
                <Translator path="components.molecules.settingsModal.expandMessages" />
              }
            />
            <Box>
              <SwitchInput
                id="switch-expand-all"
                setField={(_, value) =>
                  setSettings((old) => ({ ...old, expandAll: value }))
                }
                onChange={() => {}}
                checked={settings.expandAll}
                inputProps={{
                  'aria-labelledby': 'switch-expand-all'
                }}
              />
            </Box>
          </ListItem>

          {projectSettings?.ui.hide_cot ? null : (
            <ListItem sx={{ display: 'flex', gap: 2 }}>
              <ListItemIcon>
                <EmojiObjectsIcon />
              </ListItemIcon>
              <ListItemText
                id="hide-cot"
                primary={
                  <Translator path="components.molecules.settingsModal.hideChainOfThought" />
                }
              />
              <Box>
                <SwitchInput
                  id="switch-hide-cot"
                  setField={(_, value) =>
                    setSettings((old) => ({ ...old, hideCot: value }))
                  }
                  onChange={() => {}}
                  checked={settings.hideCot}
                  inputProps={{
                    'aria-labelledby': 'hide-cot'
                  }}
                />
              </Box>
            </ListItem>
          )}
          <ListItem sx={{ display: 'flex', gap: 2 }}>
            <ListItemIcon>
              <DarkModeOutlined />
            </ListItemIcon>
            <ListItemText
              id="switch-theme"
              primary={
                <Translator path="components.molecules.settingsModal.darkMode" />
              }
            />
            <Box>
              <SwitchInput
                id="switch-theme"
                setField={() => {
                  const variant = settings.theme === 'light' ? 'dark' : 'light';
                  localStorage.setItem('themeVariant', variant);
                  setSettings((old) => ({ ...old, theme: variant }));
                }}
                onChange={() => {}}
                checked={settings.theme === 'dark'}
                inputProps={{
                  'aria-labelledby': 'switch-theme'
                }}
              />
            </Box>
          </ListItem>
          <ListItem sx={{ display: 'flex', gap: 2 }}>
            <ListItemIcon>
              <SpeakerNotesOffOutlined />
            </ListItemIcon>
            <ListItemText
              id="settings-disable-history"
              primary={
                <Translator path="components.molecules.settingsModal.disableHistory" />
              }
            />
            <Tooltip
              title={t(
                'components.molecules.settingsModal.disableHistoryTooltip'
              )}
              placement="left"
            >
              <Typography
                component="span"
                variant="body1"
                style={{ width: 16, cursor: 'help', color: 'gray' }}
              >
                ?
              </Typography>
            </Tooltip>
            <Box>
              <SwitchInput
                id="settings-disable-history"
                setField={(_, value) => {
                  setSettings((old) => ({
                    ...old,
                    chatHistoryOptedOut: value
                  }));
                  sendEvent('toggled_history_opt_out');
                }}
                onChange={() => {}}
                checked={settings.chatHistoryOptedOut}
                inputProps={{
                  'aria-labelledby': 'settings-disable-history'
                }}
              />
            </Box>
          </ListItem>
          {enableLanguageSelector && (
            <ListItem sx={{ display: 'flex', gap: 2 }}>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('components.molecules.settingsModal.language')}
              />
              <Box
                sx={{
                  marginRight: '-8px'
                }}
              >
                <LanguageSelector availableLanguages={availableLanguages} />
              </Box>
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}
