import { useRecoilValue } from 'recoil';

import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Attachment } from '@chainlit/react-components';

import CircularProgressIconButton from 'components/atoms/buttons/progressIconButton';
import { Translator } from 'components/i18n';

import { useAttachmentImageUrl } from 'hooks/image';

import { IAttachment, attachmentsState } from 'state/chat';

const AttachmentContainer = ({ attachment }: { attachment: IAttachment }) => {
  const theme = useTheme();
  const underSm = useMediaQuery(theme.breakpoints.down('sm'));
  const showProgress = !attachment.uploaded && attachment.cancel;
  const url = useAttachmentImageUrl(attachment);

  const progress = showProgress ? (
    <Tooltip
      title={
        <Translator path="components.molecules.attachments.cancelUpload" />
      }
    >
      <Box
        sx={{
          position: 'absolute',
          right: -10,
          top: -10
        }}
      >
        <CircularProgressIconButton
          progress={attachment.uploadProgress || 0}
          onClick={attachment.cancel}
          circleSize={underSm ? 22 : 30}
          sx={{
            p: 0.5,
            background: 'white',
            backgroundColor: 'background.paper',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            '&:hover': {
              backgroundColor: 'background.default'
            }
          }}
        >
          <Close
            sx={(theme) => ({
              height: 20,
              width: 20,
              [theme.breakpoints.down('sm')]: {
                height: 12,
                width: 12
              }
            })}
          />
        </CircularProgressIconButton>
      </Box>
    </Tooltip>
  ) : null;

  const remove =
    !showProgress && attachment.remove ? (
      <Tooltip
        title={
          <Translator path="components.molecules.attachments.removeAttachment" />
        }
      >
        <IconButton
          sx={{
            position: 'absolute',
            p: 0.5,
            right: -8,
            top: -6,
            background: 'white',
            backgroundColor: 'background.paper',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            '&:hover': {
              backgroundColor: 'background.default'
            }
          }}
          onClick={attachment.remove}
        >
          <Close
            sx={(theme) => ({
              height: 16,
              width: 16,
              [theme.breakpoints.down('sm')]: {
                height: 12,
                width: 12
              }
            })}
          />
        </IconButton>
      </Tooltip>
    ) : null;

  return (
    <Attachment
      key={attachment.id}
      name={attachment.name}
      mime={attachment.type}
      url={url}
    >
      {progress}
      {remove}
    </Attachment>
  );
};

const Attachments = (): JSX.Element => {
  const attachments = useRecoilValue(attachmentsState);

  if (attachments.length === 0) return <></>;

  return (
    <Stack
      id="attachments"
      sx={{
        flexDirection: 'row',
        gap: 2,
        width: 'fit-content',
        flexWrap: 'wrap'
      }}
    >
      {attachments.map((attachment) => (
        <AttachmentContainer key={attachment.id} attachment={attachment} />
      ))}
    </Stack>
  );
};

export { Attachments };
