import { apiClient } from 'api';
import { useAuth } from 'api/auth';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Alert, Box, Stack } from '@mui/material';

import {
  accessTokenState,
  productsState,
  subscriptionState
} from '@chainlit/react-client';

import { Translator } from 'components/i18n';
import { Header } from 'components/organisms/header';
import { ThreadHistorySideBar } from 'components/organisms/threadHistory/sidebar';

import { projectSettingsState } from 'state/project';
import { userEnvState } from 'state/user';

type Props = {
  children: React.ReactNode;
};

const Page = ({ children }: Props) => {
  const { isAuthenticated, data: config } = useAuth();
  const projectSettings = useRecoilValue(projectSettingsState);
  const userEnv = useRecoilValue(userEnvState);
  const products = useRecoilValue(productsState);
  const [subscription, setSubscription] = useRecoilState(subscriptionState);
  const accessToken = useRecoilValue(accessTokenState);
  const { pathname } = useLocation();

  const fetchSubscription = async () => {
    const res = await apiClient.post(`/subscription`, {}, accessToken);

    return res.json();
  };

  useEffect(() => {
    if (
      products === null ||
      products === undefined ||
      accessToken === undefined
    ) {
      return;
    }
    fetchSubscription()
      .then((data) => {
        if (data === null) {
          // If the user has no subscription, set the subscription to undefined to indicate we have fetched it
          setSubscription({ tier: null, credits: -1, name: '', color: '' });
        }

        setSubscription({
          tier: data.tier,
          credits: data.credits,
          name: data.name,
          color: data.color
        });
      })
      .catch();
  }, [accessToken, products]);

  if (projectSettings?.userEnv) {
    for (const key of projectSettings.userEnv || []) {
      if (!userEnv[key]) return <Navigate to="/env" />;
    }
  }

  if (config?.passwordAuth || config?.headerAuth) {
    // Path taken during tests
    if (
      !isAuthenticated &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/login/callback'
    ) {
      window.location.href = '/login';
    }
  } else if (!config || config?.requireLogin) {
    // Path taken in production
    useEffect(() => {
      if (
        !isAuthenticated &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/login/callback'
      ) {
        window.location.href = '/landing';
      }
    }, []);

    if (subscription === null) {
      return null;
    }

    if (!isAuthenticated) {
      return null;
    }

    if (pathname === '/') {
      if (subscription.tier === null && subscription.credits < 0) {
        // user is signed in but not subscribed
        window.location.href = '/landing-get-started';
        return null;
      } else if (subscription.tier === 'voided_trial') {
        // user is signed in but not eligible for free trial and must resubscribe
        window.location.href = '/pricing?error=voided_trial';
        return null;
      }
    }
  }

  // The user escaped the login page
  localStorage.setItem('visited_login_last', 'false');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Header projectSettings={projectSettings} />
      {!isAuthenticated ? (
        <Alert severity="error">
          <Translator path="pages.Page.notPartOfProject" />
        </Alert>
      ) : (
        <Stack direction="row" height="100%" width="100%" overflow="auto">
          <ThreadHistorySideBar />
          {children}
        </Stack>
      )}
    </Box>
  );
};

export default Page;
