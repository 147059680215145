import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

import { projectSettingsState } from 'state/project';

function CookiePopup() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [projectSettings] = useRecoilState(projectSettingsState);

  useEffect(() => {
    // Check localStorage to see if the user has already responded to the cookie consent
    const consent = localStorage.getItem('cookieConsent');
    if (consent === null) {
      setOpen(true); // Show popup if there's no response stored
    }
  }, []);

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == 'backdropClick') {
      return;
    }
    setOpen(false);
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted'); // Save consent
    handleClose(undefined, undefined);
  };

  const handleDecline = () => {
    window.location.href = 'https://www.stability.ai';
  };

  if (!projectSettings?.showCookiePopup) {
    return null;
  }

  return (
    <Dialog
      open={open}
      keepMounted
      disableEscapeKeyDown
      BackdropProps={{
        style: { backgroundColor: 'transparent', pointerEvents: 'none' }
      }}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        '& .MuiDialog-paper': {
          zIndex: 11,
          position: 'fixed',
          bottom: 0,
          left: 0,
          margin: 3,
          width: 'auto'
        }
      }}
      style={{ position: 'initial' }}
      disableEnforceFocus
      maxWidth="xs"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('components.molecules.cookiePopup.text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept}>
          {t('components.molecules.cookiePopup.acceptButtonText')}
        </Button>
        <Button onClick={handleDecline}>
          {t('components.molecules.cookiePopup.declineButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookiePopup;
