import App from 'App';
import { useAuth } from 'api/auth';
import { validateAndConvertLanguage } from 'i18n/utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useApi } from '@chainlit/react-client';

import { apiClientState } from 'state/apiClient';
import { IProjectSettings, projectSettingsState } from 'state/project';
import { settingsState } from 'state/settings';

export default function AppWrapper() {
  const apiClient = useRecoilValue(apiClientState);
  const [projectSettings, setProjectSettings] =
    useRecoilState(projectSettingsState);
  const [{ language: languageInSettings }, setAppSettings] =
    useRecoilState(settingsState);
  const { isAuthenticated, isReady } = useAuth();
  const [enTranslation, setEnTranslation] = useState<object>({});
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);
  const { i18n } = useTranslation();

  const { data: languagesData } = useApi<string[]>(
    apiClient,
    '/project/translations'
  );

  useEffect(() => {
    if (languagesData) {
      setAvailableLanguages(languagesData);
    }
  }, [languagesData]);

  const enableLanguageSelection =
    availableLanguages.filter((lang) => lang !== 'en-US').length > 0;

  function handleChangeLanguage(
    languageInUse: string,
    languageBundle: any
  ): void {
    i18n.addResourceBundle(languageInUse, 'translation', languageBundle);
    i18n.changeLanguage(languageInUse);
  }

  // add validation as different browser use different lang codes
  const languageInUse = enableLanguageSelection
    ? validateAndConvertLanguage(languageInSettings || navigator.language)
    : 'en-US';

  const hasEnTranslation = Object.keys(enTranslation).length !== 0;

  const defaultLang = useApi<IProjectSettings>(
    apiClient,
    !hasEnTranslation ? `/project/default-translation` : null
  );

  useEffect(() => {
    if (!defaultLang.data) return;
    setEnTranslation(defaultLang.data);
    handleChangeLanguage('en-US', defaultLang.data);
  }, [defaultLang, setEnTranslation]);

  const { data } = useApi<IProjectSettings>(
    apiClient,
    projectSettings === undefined && isAuthenticated
      ? `/project/settings?language=${languageInUse}`
      : null
  );

  if (
    isReady &&
    !isAuthenticated &&
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/login/callback'
  ) {
    window.location.href = '/landing';
  }

  useEffect(() => {
    if (!data) return;
    setProjectSettings(data);
    setAppSettings((prev) => ({
      ...prev,
      defaultCollapseContent: data.ui.default_collapse_content ?? true,
      expandAll: !!data.ui.default_expand_messages,
      hideCot: !!data.ui.hide_cot
    }));
    handleChangeLanguage(languageInUse, data.translation);
  }, [data, setProjectSettings, setAppSettings]);

  if (!hasEnTranslation) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  return <App />;
}
