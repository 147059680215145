import { useFormik } from 'formik';
import {
  KeyboardEventHandler,
  MouseEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import { TextInput } from '@chainlit/react-components';

const MAX_PADDING = 2000;

type DragDirection = 'top' | 'left' | 'bottom' | 'right';

type ZoomOutOverlayProps = {
  id: string;
  imgUrl: string;
  zoom: number;
  onCanvasReady: (
    canvasSize: [
      canvasWidth: number,
      canvasHeight: number,
      chromeWidth: number,
      chromeHeight: number
    ]
  ) => void;
  formik: ReturnType<typeof useFormik>;
};

// just for calculating the zoom levels
const CHROME_WIDTH = 300;
const CHROME_HEIGHT = 150;

const arrowKeys = new Set(['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']);

export const ZoomOutOverlay = ({
  imgUrl,
  zoom,
  onCanvasReady,
  formik
}: ZoomOutOverlayProps) => {
  const theme = useTheme();
  const isMouseUser = useMediaQuery('(pointer: fine)');

  const imageRef = useRef<HTMLImageElement>(null);

  const directionRef = useRef<DragDirection>();
  const { t } = useTranslation();
  const locationRef = useRef<[number, number] | null>(null);

  const { up = 0, down = 0, right = 0, left = 0 } = formik.values;

  const originalWidth = imageRef.current?.naturalWidth || 0;
  const originalHeight = imageRef.current?.naturalHeight || 0;
  const width = originalWidth + right + left;
  const height = originalHeight + up + down;

  const maxWidth = originalWidth + MAX_PADDING * 2;
  const maxHeight = originalHeight + MAX_PADDING * 2;

  // has to be controlled to stay in sync with the drag, but
  // we also want it to be less restricted when typing, kind of a pain TBH
  const [userWidth, setUserWidth] = useState(0);
  const [userHeight, setUserHeight] = useState(0);

  // there's some funny business with formik and the image onload in safari meaning
  // we don't have this data available in the onload event, so making our own one here
  useEffect(() => {
    if (originalWidth > 0 && originalHeight > 0) {
      const { up, down, left, right } = formik.initialValues;
      const canvasWidth = originalWidth + left + right;
      const canvasHeight = originalHeight + up + down;
      setUserWidth(canvasWidth);
      setUserHeight(canvasHeight);
      onCanvasReady([canvasWidth, canvasHeight, CHROME_WIDTH, CHROME_HEIGHT]);
    }
  }, [originalWidth, originalHeight, formik.initialValues]);

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    if (element.classList.contains('cropHandle')) {
      element.parentElement?.focus();
      locationRef.current = [e.screenX, e.screenY];
      directionRef.current = element.dataset.direction as DragDirection;
    }
  };

  const disableDrag = () => {
    if (locationRef.current) {
      locationRef.current = null;
    }
  };

  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (locationRef.current) {
      const [startX, startY] = locationRef.current;
      const dir = directionRef.current;
      if (dir === 'top') {
        const dy = Math.floor((startY - e.screenY) / zoom);
        const u2 = Math.min(Math.max(up + dy, 0), MAX_PADDING);
        formik.setFieldValue('up', u2);
      } else if (dir === 'bottom') {
        const dy = Math.floor((e.screenY - startY) / zoom);
        const d2 = Math.min(Math.max(down + dy, 0), MAX_PADDING);
        formik.setFieldValue('down', d2);
      } else if (dir === 'left') {
        const dx = Math.floor((startX - e.screenX) / zoom);
        const l2 = Math.min(Math.max(left + dx, 0), MAX_PADDING);
        formik.setFieldValue('left', l2);
      } else if (dir === 'right') {
        const dx = Math.floor((e.screenX - startX) / zoom);
        const r2 = Math.min(Math.max(right + dx, 0), MAX_PADDING);
        formik.setFieldValue('right', r2);
      }
      // TODO: these are slightly out of date, but should be close enough...
      setUserWidth(Math.round(width));
      setUserHeight(Math.round(height));
      locationRef.current = [e.screenX, e.screenY];
    }
  };

  // stop the drag if you leave the entire browser window
  useEffect(() => {
    const onMouseLeave = () => {
      locationRef.current = null;
    };
    document.documentElement.addEventListener('mouseleave', onMouseLeave);
    return () =>
      document.documentElement.removeEventListener('mouseLeave', onMouseLeave);
  }, []);

  const [translateImage, setTranslateImage] = useState([0, 0]);
  const imageMovingRef = useRef<null | [number, number]>(null);

  const onImageDragComplete = () => {
    if (imageMovingRef.current) {
      imageMovingRef.current = null;
      setTranslateImage([0, 0]);
      const [dx, dy] = translateImage;
      const dxz = dx / zoom;
      const dyz = dy / zoom;
      const u2 = Math.round(Math.max(Math.min(up + dyz, MAX_PADDING), 0));
      const r2 = Math.round(Math.max(Math.min(right - dxz, MAX_PADDING), 0));
      const d2 = Math.round(Math.max(Math.min(down - dyz, MAX_PADDING), 0));
      const l2 = Math.round(Math.max(Math.min(left + dxz, MAX_PADDING), 0));
      const prompt = formik.values.prompt;
      formik.setValues({ up: u2, down: d2, right: r2, left: l2, prompt });
      setUserWidth(l2 + r2 + originalWidth);
      setUserHeight(u2 + d2 + originalHeight);
    }
  };
  const onImageMouseDown = (e: MouseEvent<HTMLImageElement>) => {
    const img = e.target as HTMLImageElement;
    img.focus();
    imageMovingRef.current = [e.screenX, e.screenY];
  };

  const onImageMouseMove = (e: MouseEvent) => {
    if (imageMovingRef.current) {
      const [x, y] = [e.screenX, e.screenY];
      const [oldX, oldY] = imageMovingRef.current;
      const dx = x - oldX;
      const dy = y - oldY;

      // basic bounds checking
      const maxDx = right * zoom;
      const minDx = -1 * left * zoom;
      const maxDy = down * zoom;
      const minDy = -1 * up * zoom;
      setTranslateImage([
        Math.min(Math.max(dx, minDx), maxDx),
        Math.min(Math.max(dy, minDy), maxDy)
      ]);
    }
  };
  const onKeyDown: KeyboardEventHandler<HTMLImageElement> = (e) => {
    if (arrowKeys.has(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
    const distance = e.shiftKey ? -5 : 5;
    switch (e.key) {
      case 'ArrowDown': {
        const d2 = down + distance;
        if (d2 < 0 || d2 > MAX_PADDING) return;
        formik.setFieldValue('down', d2);
        break;
      }
      case 'ArrowUp': {
        const u2 = up + distance;
        if (u2 < 0 || u2 > MAX_PADDING) return;
        formik.setFieldValue('up', u2);
        break;
      }
      case 'ArrowRight': {
        const r2 = right + distance;
        if (r2 < 0 || r2 > MAX_PADDING) return;
        formik.setFieldValue('right', r2);
        break;
      }
      case 'ArrowLeft': {
        const l2 = left + distance;
        if (l2 < 0 || l2 > MAX_PADDING) return;
        formik.setFieldValue('left', l2);
        break;
      }
    }
  };

  const onImageKeyDown: KeyboardEventHandler<HTMLImageElement> = (e) => {
    if (arrowKeys.has(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
    const distance = e.shiftKey ? 15 : 2;
    switch (e.key) {
      case 'ArrowDown': {
        const d2 = Math.max(down - distance, 0);
        const u2 = Math.min(up + down - d2, MAX_PADDING);
        formik.setFieldValue('up', u2);
        formik.setFieldValue('down', d2);
        setUserHeight(u2 + d2 + originalHeight);
        return;
      }
      case 'ArrowUp': {
        const u2 = Math.max(up - distance, 0);
        const d2 = Math.min(up + down - u2, MAX_PADDING);
        formik.setFieldValue('up', u2);
        formik.setFieldValue('down', d2);
        setUserHeight(u2 + d2 + originalHeight);
        return;
      }
      case 'ArrowLeft': {
        const l2 = Math.max(left - distance, 0);
        const r2 = Math.min(right + left - l2, MAX_PADDING);
        formik.setFieldValue('right', r2);
        formik.setFieldValue('left', l2);
        setUserWidth(l2 + r2 + originalWidth);
        return;
      }
      case 'ArrowRight': {
        const r2 = Math.max(right - distance, 0);
        const l2 = Math.min(right + left - r2, MAX_PADDING);
        formik.setFieldValue('right', r2);
        formik.setFieldValue('left', l2);
        setUserWidth(l2 + r2 + originalWidth);
        return;
      }
    }
  };

  const reset = () => {
    const prompt = formik.values.prompt; // keeping the old prompt for now
    formik.setValues({ up: 0, down: 0, right: 0, left: 0, prompt });
    setUserWidth(originalWidth);
    setUserHeight(originalHeight);
  };

  return (
    <Stack
      marginTop="24px"
      justifyContent="start"
      alignItems={{ xs: 'center', sm: 'stretch' }}
      direction={{ xs: 'column', sm: 'row' }}
      gap="1em"
    >
      <Stack
        role="toolbar"
        direction="column"
        justifyContent="start"
        gap="1em"
        sx={{
          padding: '0 2em 1em',
          backgroundColor: theme.palette.background.paper
        }}
        zIndex={2}
      >
        <Stack width={{ xs: '100%', sm: 220 }}>
          <TextInput
            label={t('components.molecules.zoomOutOverlay.prompt.label')}
            // this is soo slow, so we're trying onBlur instead, which apppears to work
            // onChange={formik.handleChange}
            placeholder={t(
              'components.molecules.zoomOutOverlay.prompt.placeholder'
            )}
            onBlur={formik.handleChange}
            rows={2}
            tooltip={t(
              'components.molecules.zoomOutOverlay.prompt.description'
            )}
            InputLabelProps={{
              sx: {
                'white-space': 'normal',
                color: theme.palette.text.secondary
              }
            }}
            multiline={isMouseUser}
            id="prompt"
          />
        </Stack>
        <Stack
          direction={{ xs: 'row', sm: 'column' }}
          gap="1em"
          justifyContent="space-between"
        >
          <Typography variant="h6" display={{ xs: 'none', sm: 'block' }}>
            {t('components.molecules.zoomOutOverlay.headings.dimensions')}
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap="1em">
            <TextField
              type="number"
              label={t('components.molecules.zoomOutOverlay.labels.width')}
              value={userWidth}
              onBlur={(e) => {
                const newWidth = parseInt(e.target.value, 10);
                if (newWidth > maxWidth) {
                  setUserWidth(maxWidth);
                  formik.setFieldValue('right', MAX_PADDING);
                  formik.setFieldValue('left', MAX_PADDING);
                } else if (newWidth < originalWidth) {
                  setUserWidth(originalWidth);
                  formik.setFieldValue('right', 0);
                  formik.setFieldValue('left', 0);
                }
              }}
              onChange={(e) => {
                const newWidth = parseInt(e.target.value, 10);
                const dw = Math.round((newWidth - width) / 2);
                const r2 = Math.min(Math.max(right + dw, 0), MAX_PADDING) || 0;
                const l2 = Math.min(Math.max(left + dw, 0), MAX_PADDING) || 0;
                formik.setFieldValue('right', r2);
                formik.setFieldValue('left', l2);
                setUserWidth(newWidth);
              }}
              style={{ width: 100 }}
              inputProps={{ min: originalWidth, max: maxWidth, step: '1' }}
            />
            <TextField
              type="number"
              label={t('components.molecules.zoomOutOverlay.labels.height')}
              value={userHeight}
              onBlur={(e) => {
                const newHeight = parseInt(e.target.value, 10);
                if (newHeight > maxHeight) {
                  setUserHeight(maxHeight);
                  formik.setFieldValue('up', MAX_PADDING);
                  formik.setFieldValue('down', MAX_PADDING);
                } else if (newHeight < originalHeight) {
                  setUserHeight(originalHeight);
                  formik.setFieldValue('up', 0);
                  formik.setFieldValue('down', 0);
                }
              }}
              onChange={(e) => {
                const newHeight = parseInt(e.target.value, 10);
                const dh = Math.round((newHeight - height) / 2);
                const u2 = Math.min(Math.max(up + dh, 0), MAX_PADDING) || 0;
                const d2 = Math.min(Math.max(down + dh, 0), MAX_PADDING) || 0;
                formik.setFieldValue('up', u2);
                formik.setFieldValue('down', d2);
                setUserHeight(newHeight);
              }}
              style={{ width: 100 }}
              inputProps={{ min: originalHeight, max: maxHeight, step: '1' }}
            />
          </Stack>
          <FormControl>
            <Stack gap="1em">
              <Typography variant="h6" display={{ xs: 'none', sm: 'block' }}>
                {t('components.molecules.zoomOutOverlay.headings.placement')}
              </Typography>
              <Stack
                direction="column"
                gap="1em"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  gap="1em"
                  minWidth={200}
                  width={{ sm: 200 }}
                >
                  <TextField
                    label={t('components.molecules.zoomOutOverlay.labels.top')}
                    type="number"
                    onChange={(e) => {
                      const val = parseInt(e.target.value, 10);
                      const u2 = Math.min(Math.max(val, 0), MAX_PADDING) || 0;
                      formik.setFieldValue('up', u2);
                      setUserHeight(u2 + down + originalHeight);
                    }}
                    value={up}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label={t(
                      'components.molecules.zoomOutOverlay.labels.right'
                    )}
                    type="number"
                    onChange={(e) => {
                      const val = parseInt(e.target.value, 10);
                      const r2 = Math.min(Math.max(val, 0), MAX_PADDING) || 0;
                      formik.setFieldValue('right', r2);
                      setUserWidth(r2 + left + originalWidth);
                    }}
                    value={right}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  gap="1em"
                  minWidth={200}
                  width={{ sm: 200 }}
                >
                  <TextField
                    label={t(
                      'components.molecules.zoomOutOverlay.labels.bottom'
                    )}
                    type="number"
                    onChange={(e) => {
                      const val = parseInt(e.target.value, 10);
                      const d2 = Math.min(Math.max(val, 0), MAX_PADDING) || 0;
                      formik.setFieldValue('down', d2);
                      setUserHeight(d2 + up + originalHeight);
                    }}
                    value={down}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label={t('components.molecules.zoomOutOverlay.labels.left')}
                    type="number"
                    onChange={(e) => {
                      const val = parseInt(e.target.value, 10);
                      const l2 = Math.min(Math.max(val, 0), MAX_PADDING) || 0;
                      formik.setFieldValue('left', l2);
                      setUserWidth(l2 + right + originalHeight);
                    }}
                    value={left}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
              </Stack>

              <ToggleButtonGroup
                color="primary"
                sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
              >
                <Tooltip
                  title={t('components.molecules.zoomOutOverlay.tooltips.left')}
                >
                  <div>
                    <ToggleButton
                      value="left"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.left'
                      )}
                      onClick={() => {
                        let r2 = right + left;
                        r2 = Math.min(r2, MAX_PADDING);
                        formik.setFieldValue('right', r2);
                        formik.setFieldValue('left', 0);
                        setUserWidth(originalWidth + r2);
                      }}
                    >
                      <VerticalAlignBottomIcon
                        sx={{ transform: 'rotate(90deg)' }}
                      />
                    </ToggleButton>
                  </div>
                </Tooltip>

                <Tooltip
                  title={t(
                    'components.molecules.zoomOutOverlay.tooltips.center'
                  )}
                >
                  <div>
                    <ToggleButton
                      value="center"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.center'
                      )}
                      onClick={() => {
                        const pad = Math.round((left + right) / 2);
                        formik.setFieldValue('left', pad);
                        formik.setFieldValue('right', pad);
                      }}
                    >
                      <VerticalAlignCenterIcon
                        sx={{ transform: 'rotate(90deg)' }}
                      />
                    </ToggleButton>
                  </div>
                </Tooltip>

                <Tooltip
                  title={t(
                    'components.molecules.zoomOutOverlay.tooltips.right'
                  )}
                >
                  <div>
                    <ToggleButton
                      value="right"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.right'
                      )}
                      onClick={() => {
                        let l2 = right + left;
                        l2 = Math.min(l2, MAX_PADDING);
                        formik.setFieldValue('right', 0);
                        formik.setFieldValue('left', l2);
                        setUserWidth(originalWidth + l2);
                      }}
                    >
                      <VerticalAlignBottomIcon
                        sx={{ transform: 'rotate(270deg)' }}
                      />
                    </ToggleButton>
                  </div>
                </Tooltip>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                color="primary"
                sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
              >
                <Tooltip
                  title={t('components.molecules.zoomOutOverlay.tooltips.top')}
                >
                  <div>
                    <ToggleButton
                      value="top"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.right'
                      )}
                      onClick={() => {
                        let d2 = up + down;
                        d2 = Math.min(d2, MAX_PADDING);
                        formik.setFieldValue('up', 0);
                        formik.setFieldValue('down', d2);
                        setUserHeight(originalHeight + d2);
                      }}
                    >
                      <VerticalAlignBottomIcon
                        sx={{ transform: 'rotate(180deg)' }}
                      />
                    </ToggleButton>
                  </div>
                </Tooltip>
                <Tooltip
                  title={t(
                    'components.molecules.zoomOutOverlay.tooltips.middle'
                  )}
                >
                  <div>
                    <ToggleButton
                      value="middle"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.middle'
                      )}
                      onClick={() => {
                        const padding = Math.round((down + up) / 2);
                        formik.setFieldValue('up', padding);
                        formik.setFieldValue('down', padding);
                      }}
                    >
                      <VerticalAlignCenterIcon />
                    </ToggleButton>
                  </div>
                </Tooltip>
                <Tooltip
                  title={t(
                    'components.molecules.zoomOutOverlay.tooltips.bottom'
                  )}
                >
                  <div>
                    <ToggleButton
                      value="bottom"
                      aria-label={t(
                        'components.molecules.zoomOutOverlay.tooltips.bottom'
                      )}
                      onClick={() => {
                        let u2 = up + down;
                        u2 = Math.min(u2, MAX_PADDING);
                        formik.setFieldValue('up', u2);
                        formik.setFieldValue('down', 0);
                        setUserHeight(originalHeight + u2);
                      }}
                    >
                      <VerticalAlignBottomIcon />
                    </ToggleButton>
                  </div>
                </Tooltip>
              </ToggleButtonGroup>
            </Stack>
          </FormControl>
        </Stack>
        <Stack display={{ xs: 'none', sm: 'block' }}>
          <Button
            onClick={reset}
            variant="outlined"
            color="inherit"
            sx={{
              borderColor: theme.palette.action.disabled
            }}
          >
            {t('components.molecules.zoomOutOverlay.reset')}
          </Button>
        </Stack>
      </Stack>
      <Stack
        draggable="false"
        sx={{ userSelect: 'none' }}
        display={{ xs: 'block', sm: 'flex' }}
        className="cropContainer"
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={disableDrag}
        onMouseLeave={disableDrag}
        width="100%"
        height="100%"
        minHeight={{ xs: '40vh', sm: '80vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          draggable="false"
          margin={{ xs: '0 auto', sm: 0 }}
          width={width * zoom}
          height={height * zoom}
          tabIndex={0}
          onMouseMove={onImageMouseMove}
          onMouseUp={onImageDragComplete}
          onMouseLeave={onImageDragComplete}
          sx={{
            outlineColor: theme.palette.primary.main,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.text.secondary
                : theme.palette.background.paper,
            backgroundImage:
              'linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)',
            backgroundSize: '20px 20px',
            backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
            border: `2px solid ${theme.palette.text.secondary}`,
            position: 'relative'
          }}
        >
          {isMouseUser && (
            <>
              <CropHandle theme={theme} position="top" />
              <CropHandle theme={theme} position="right" />
              <CropHandle theme={theme} position="bottom" />
              <CropHandle theme={theme} position="left" />
            </>
          )}
          <div draggable={false} style={{ position: 'relative' }}>
            <img
              ref={imageRef}
              onKeyDown={onImageKeyDown}
              onMouseDown={onImageMouseDown}
              tabIndex={0}
              style={{
                outlineColor: theme.palette.primary.main,
                position: 'absolute',
                transform: `translate(${translateImage[0]}px, ${translateImage[1]}px)`,
                top: up * zoom,
                left: left * zoom,
                cursor: 'move'
              }}
              draggable={false}
              src={imgUrl}
              width={originalWidth * zoom}
              height={originalHeight * zoom}
            />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

type CropHandleProps = {
  position: 'top' | 'right' | 'bottom' | 'left';
  theme: Theme;
};
const CropHandle = ({ position, theme }: CropHandleProps) => {
  const isVertical = ['right', 'left'].includes(position);
  const size = isVertical
    ? { width: 10, height: 20 }
    : { width: 20, height: 10 };
  const cursor = isVertical ? 'ew' : 'ns';
  let top, bottom, right, left, deg;
  switch (position) {
    case 'top':
      deg = 0;
      left = 'calc(50% - 10px)';
      top = -6;
      break;
    case 'right':
      deg = 90;
      top = 'calc(50% - 10px)';
      right = -7.5;
      break;
    case 'bottom':
      deg = 180;
      left = 'calc(50% - 10px)';
      bottom = -7.5;
      break;
    case 'left':
      deg = 270;
      top = 'calc(50% - 10px)';
      left = -8;
      break;
  }

  return (
    <Stack
      className="cropHandle"
      data-direction={position}
      sx={{
        zIndex: 1,
        border: `1px solid ${theme.palette.text.secondary}`,
        boxShadow: `0 0 2px ${theme.palette.background.paper}`,
        position: 'absolute',
        borderRadius: '3px',
        background: `linear-gradient(${deg}deg, #a9a9a9 0, #8a8a8a 50%, #343434 100%)`,
        cursor: `${cursor}-resize`,
        top,
        left,
        right,
        bottom,
        ...size
      }}
    />
  );
};
