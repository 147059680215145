import { atom } from 'recoil';

export const defaultGenerationShareModalState = {
  open: false,
  elementId: '',
  type: '',
  sharedUuid: ''
};

export const generationShareModalState = atom<{
  open: boolean;
  elementId: string | undefined;
  type: string | undefined;
  sharedUuid: string | undefined;
}>({
  key: 'GenerationShareModalState',
  default: defaultGenerationShareModalState
});

const shareCheckboxStorageKey = 'ShareCheckboxState';

const getDefaultCheckboxState = () => {
  const storedData = localStorage.getItem(shareCheckboxStorageKey);
  const items = storedData ? JSON.parse(storedData) : {};
  return {
    admin: items.admin ?? false,
    public: items.public ?? true
  };
};

export const shareCheckboxState = atom<{
  admin: boolean;
  public: boolean;
}>({
  key: 'ShareCheckboxState',
  default: getDefaultCheckboxState(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(shareCheckboxStorageKey, JSON.stringify(newValue));
      });
    }
  ]
});
