import { imageElementsMatch } from 'helpers/element';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList
} from '@mui/material';

import {
  ImageSelectorModalProps,
  ImageThreadElement,
  useChatData,
  useChatInteract
} from '@chainlit/react-client';
import { UploadContext } from '@chainlit/react-client';
import { AccentButton, RegularButton } from '@chainlit/react-components';

import UploadButton from 'components/organisms/chat/inputBox/UploadButton';

import { apiClientState } from 'state/apiClient';
import { IAttachment } from 'state/chat';

import AttachmentItem from './attachmentItem';
import ImageItem from './imageItem';

export interface ImageSelectorModalComponentProps {
  state: RecoilState<ImageSelectorModalProps>;
  onSelectImage: (element: ImageThreadElement | null) => void;
  uploadContext: UploadContext;
  translationKey: string;
}

const ImageSelectorModal = ({
  state,
  onSelectImage,
  uploadContext,
  translationKey
}: ImageSelectorModalComponentProps) => {
  const [modalState, setToolModalState] =
    useRecoilState<ImageSelectorModalProps>(state);
  const { elements } = useChatData();
  const { uploadFile } = useChatInteract();
  const apiClient = useRecoilValue(apiClientState);

  const [candidateImage, setCandidateImage] =
    useState<ImageThreadElement | null>(null);
  const [attachment, setAttachment] = useState<IAttachment | null>(null);
  const { t } = useTranslation();

  const images: ImageThreadElement[] = elements
    .filter((element) => element.type === 'image' && element.threadId)
    .map(
      (element): ImageThreadElement => ({
        elementId: element.id,
        threadId: element.threadId as string
      })
    );

  const handleClose = () =>
    setToolModalState((state) => ({ ...state, open: false }));

  const removeAttachment = () => {
    if (imageElementsMatch(attachment, candidateImage)) {
      setCandidateImage(null);
    }

    if (attachment?.cancel) {
      attachment.cancel();
    } else if (attachment?.remove) {
      attachment.remove();
    }

    setAttachment(null);
  };

  const handleConfirm = async () => {
    // Set field value of image overlay UI when the confirm button is clicked
    onSelectImage(candidateImage);
    setToolModalState({ open: false, selectedElement: candidateImage });
  };

  useEffect(() => {
    const element = modalState.selectedElement;
    setCandidateImage(element);
  }, [modalState.open]);

  const toggleCandidateImage = (item: ImageThreadElement): void => {
    setCandidateImage((candidateImage) =>
      imageElementsMatch(item, candidateImage) ? null : item
    );
  };

  const onFileUpload = (payloads: File[]) => {
    removeAttachment();

    // We allow single file upload
    if (payloads.length !== 1) {
      // TODO: Show error message to user?
      return;
    }
    const file = payloads[0];

    const id = uuidv4();

    setAttachment({
      id,
      type: file.type,
      name: file.name,
      size: file.size,
      uploadProgress: 0,
      cancel: () => {
        xhr.abort();
        setAttachment(null);
      },
      remove: () => setAttachment(null)
    });

    const { xhr, promise } = uploadFile(
      apiClient,
      file,
      uploadContext,
      (progress) => {
        setAttachment(
          (prev) =>
            prev && {
              ...prev,
              // Hack: Cap the progress at 95% until getting a response.
              //       Otherwise user might be confused by seeing not done for a while
              //       after reaching 100% if validation in the server takes time.
              uploadProgress: Math.min(progress, 95)
            }
        );
      }
    );

    toast.promise(promise, {
      loading: `${t('components.organisms.chat.index.uploading')} ${file.name}`,
      success: `${t('components.organisms.chat.index.uploaded')} ${file.name}`,
      error: (error) => {
        // Ugh...
        // TODO: Remove the magic keyword "Aborted"
        // TODO: Any way to show the info icon for canceling?
        if (error.message === 'Aborted') {
          return `${t('components.organisms.chat.index.cancelledUploadOf')} ${
            file.name
          }`;
        }
        return `${t('components.organisms.chat.index.failedToUpload')} ${
          file.name
        }: ${error.message}`;
      }
    });

    promise
      .then((res) => {
        setAttachment(
          (prev) =>
            prev && {
              ...prev,
              // Update with the server ID
              serverId: res.id,
              threadId: res.threadId,
              uploaded: true,
              uploadProgress: 100,
              elementInfoForTools: res.elementInfoForTools,
              cancel: undefined
            }
        );
      })
      .catch(() => {
        setAttachment(null);
      });
  };

  const onFileUploadError = useCallback(
    () => (error: string) => toast.error(error),
    []
  );

  useEffect(() => {
    if (attachment?.serverId && attachment.threadId) {
      setCandidateImage({
        elementId: attachment.serverId,
        threadId: attachment.threadId
      });
    }
  }, [attachment]);

  const handleClear = () => setCandidateImage(null);

  return (
    <Dialog
      open={modalState?.open || false}
      onClose={handleClose}
      id="chat-settings-dialog"
      PaperProps={{
        sx: {
          backgroundImage: 'none'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {t(`${translationKey}.title`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(`${translationKey}.description`)}
        </DialogContentText>
        <DialogActions sx={{ p: 1 }}>
          {!attachment && (
            <UploadButton
              disabled={false}
              onFileUpload={onFileUpload}
              onFileUploadError={onFileUploadError}
              withLabel={true}
            />
          )}
          {attachment && (
            <AttachmentItem
              attachment={attachment}
              removeAttachment={removeAttachment}
              toggleCandidateImage={toggleCandidateImage}
              candidateImage={candidateImage}
            />
          )}
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
        <ImageList cols={images.length > 4 ? 3 : 2} gap={8}>
          {images.map((item) => (
            <Fragment key={item.elementId}>
              <ImageItem
                item={item}
                toggleCandidateImage={toggleCandidateImage}
                candidateImage={candidateImage}
                translationKey={translationKey}
                t={t}
              />
            </Fragment>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <RegularButton onClick={handleClose}>
          {t(`${translationKey}.cancel`)}
        </RegularButton>
        <AccentButton
          variant="outlined"
          onClick={handleClear}
          disabled={candidateImage === null}
        >
          {t(`${translationKey}.clear`)}
        </AccentButton>
        <AccentButton
          id="confirm"
          variant="outlined"
          onClick={handleConfirm}
          autoFocus
        >
          {t(`${translationKey}.confirm`)}
        </AccentButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImageSelectorModal;
