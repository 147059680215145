import { useAuth } from 'api/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Circle, StarOutline } from '@mui/icons-material';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';

import {
  accessTokenState,
  creditsState,
  productsState,
  sendEvent,
  subscriptionState
} from '@chainlit/react-client';
import { useIsDarkMode } from '@chainlit/react-components';

import { Translator } from 'components/i18n';

import { apiClientState } from 'state/apiClient';
import { projectSettingsState } from 'state/project';
import { settingsState } from 'state/settings';

interface Props {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
}

export default function UserMenu({ anchorEl, open, handleClose }: Props) {
  const { user, logout } = useAuth();
  const [telegramLinkState, setTelegramLinkState] = useState<
    undefined | boolean
  >(undefined);
  const pSettings = useRecoilValue(projectSettingsState);
  const creditCount = useRecoilValue(creditsState);
  const subscription = useRecoilValue(subscriptionState);
  const isDarkMode = useIsDarkMode();
  const setAppSettings = useSetRecoilState(settingsState);
  const requiredKeys = !!pSettings?.userEnv?.length;
  const apiClient = useRecoilValue(apiClientState);
  const accessToken = useRecoilValue(accessTokenState);
  const products = useRecoilValue(productsState);
  const { t } = useTranslation();
  const [showRedCircle, setShowRedCircle] = useState(
    localStorage.getItem('hasClickedTelegram') !== 'true'
  );

  useEffect(() => {
    if (!apiClient || !accessToken) {
      return;
    }
    apiClient.get(`/is_telegram_linked`, accessToken).then((res) => {
      res.json().then((data) => {
        setTelegramLinkState(data.linked);
      });
    });
  }, [apiClient, accessToken]);

  const userNameItem = user && (
    <ListItem key="user-name" sx={{ display: 'flex', flexDirection: 'column' }}>
      {subscription && subscription.tier != '' && (
        <Chip
          variant="outlined"
          label={subscription.name}
          sx={{
            width: '90%',
            mb: 2,
            borderColor: subscription.color,
            color: subscription.color
          }}
        />
      )}
      <Typography width="100%" fontSize="14px" fontWeight={700}>
        {user.id}
      </Typography>
      <Typography width="100%" fontSize="13px" fontWeight={400}>
        {user.identifier}
      </Typography>
    </ListItem>
  );

  const settingsItem = (
    <MenuItem
      key="settings"
      onClick={() => {
        sendEvent('opened_settings');
        setAppSettings((old) => ({ ...old, open: true }));
        handleClose();
      }}
    >
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        <Translator path="components.atoms.buttons.userButton.menu.settings" />
      </ListItemText>
      <Typography variant="body2" color="text.secondary">
        <Translator path="components.atoms.buttons.userButton.menu.settingsKey" />
      </Typography>
    </MenuItem>
  );

  const apiKeysItem = requiredKeys && (
    <MenuItem key="env" component={Link} to="/env">
      <ListItemIcon>
        <KeyIcon fontSize="small" />
      </ListItemIcon>
      <Translator path="components.atoms.buttons.userButton.menu.APIKeys" />
    </MenuItem>
  );

  const logoutItem = user && (
    <MenuItem
      key="logout"
      onClick={async () => {
        const logout_url_if_any = await logout();
        handleClose();

        if (logout_url_if_any !== undefined) {
          window.location.href = logout_url_if_any;
        }
        sendEvent('logout');
      }}
    >
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>
      <Translator path="components.atoms.buttons.userButton.menu.logout" />
    </MenuItem>
  );

  const subscriptionItem = products !== null &&
    products !== undefined &&
    products?.length > 1 &&
    subscription !== null && (
      <MenuItem
        key="subscription"
        onClick={async () => {
          const res = await apiClient.post(`/manage`, {}, accessToken);
          sendEvent(`manage_subscription`);
          if (res.status !== 200) {
            // TODO: display error?
            return;
          }
          const data = await res.json();
          window.location.href = data.url;
        }}
      >
        <ListItemIcon>
          <StarOutline fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography>Manage Subscription</Typography>
        </ListItemText>
      </MenuItem>
    );

  const creditsItem = products !== undefined && creditCount && (
    <MenuItem key="credits">
      <ListItemIcon>
        <svg
          width="20"
          height="20"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="17"
            r="14.72"
            stroke="#FFB341"
            filter={isDarkMode ? 'grayscale(50%)' : 'grayscale(0%)'}
            stroke-width="2.56"
          />
          <circle
            cx="16"
            cy="17"
            r="11.5"
            fill="#FFB341"
            filter={isDarkMode ? 'grayscale(50%)' : 'grayscale(0%)'}
          />
          <path
            d="M16.9434 9.12891V11.9805H14.9902V9.12891H16.9434ZM16.9434 21.5508V24.3926H14.9902V21.5508H16.9434ZM15.8398 19.6562C16.1458 19.6562 16.4062 19.6009 16.6211 19.4902C16.8424 19.373 17.0085 19.2103 17.1191 19.002C17.2363 18.7871 17.2917 18.5299 17.2852 18.2305H20.3809C20.3874 19.0247 20.1921 19.7214 19.7949 20.3203C19.4043 20.9128 18.8704 21.375 18.1934 21.707C17.5228 22.0326 16.7643 22.1953 15.918 22.1953C15.0911 22.1953 14.3685 22.0618 13.75 21.7949C13.1315 21.5215 12.6139 21.1439 12.1973 20.6621C11.7871 20.1738 11.4779 19.6042 11.2695 18.9531C11.0677 18.3021 10.9668 17.5957 10.9668 16.834V16.6094C10.9668 15.8477 11.0677 15.1413 11.2695 14.4902C11.4779 13.8327 11.7871 13.263 12.1973 12.7812C12.6139 12.293 13.1283 11.9154 13.7402 11.6484C14.3587 11.375 15.0781 11.2383 15.8984 11.2383C16.7773 11.2383 17.5521 11.4043 18.2227 11.7363C18.8997 12.0684 19.4303 12.5501 19.8145 13.1816C20.1986 13.8132 20.3874 14.5781 20.3809 15.4766H17.2852C17.2917 15.1576 17.2428 14.8711 17.1387 14.6172C17.0345 14.3633 16.8717 14.1615 16.6504 14.0117C16.4355 13.8555 16.1589 13.7773 15.8203 13.7773C15.4818 13.7773 15.2083 13.8522 15 14.002C14.7917 14.1517 14.6322 14.36 14.5215 14.627C14.4173 14.8874 14.3457 15.1868 14.3066 15.5254C14.2741 15.8639 14.2578 16.2253 14.2578 16.6094V16.834C14.2578 17.2246 14.2741 17.5924 14.3066 17.9375C14.3457 18.276 14.4206 18.5755 14.5312 18.8359C14.6419 19.0898 14.8014 19.2917 15.0098 19.4414C15.2181 19.5846 15.4948 19.6562 15.8398 19.6562Z"
            fill="white"
          />
        </svg>
      </ListItemIcon>
      <Typography
        sx={{ color: '#FFB341', filter: isDarkMode ? 'grayscale(50%)' : '' }}
      >
        {creditCount}
      </Typography>
    </MenuItem>
  );

  const telegramLinkItem = pSettings?.features?.telegram_bot_name &&
    telegramLinkState !== undefined && (
      <MenuItem
        key="telegramLink"
        onClick={() => {
          localStorage.setItem('hasClickedTelegram', 'true');
          setShowRedCircle(false);

          const newWindow = window.open('https://t.me/' + pSettings?.features?.telegram_bot_name, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }}
      >
        <ListItemIcon>
          <TelegramIcon fontSize="small" style={{ color: '#419FD9' }} />
          {showRedCircle && (
            <Circle
              sx={{
                alignItems: 'flex-start',
                position: 'absolute',
                transform: 'scale(0.6)',
                top: 0,
                left: 0,
                color: '#ff0000'
              }}
            />
          )}
        </ListItemIcon>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ListItemText>
            <Typography
              style={{
                paddingTop: '5px',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                minWidth: '100%',
                filter: isDarkMode ? 'grayscale(50%)' : ''
              }}
              color={'#419FD9'}
            >
              {t('components.atoms.buttons.userButton.menu.telegramLinkButtonStart')}
            </Typography></ListItemText>
          <ListItemText>

            {telegramLinkState && (
              <Chip
                label={"@" + pSettings?.features?.telegram_bot_name}
                sx={{
                  backgroundColor: (theme) => `${isDarkMode ? theme.palette.info.dark : theme.palette.info.light}`,
                  '& .MuiChip-label': {
                    overflow: 'visible'
                  },
                  filter: isDarkMode ? 'grayscale(50%)' : '',
                  fontSize: '0.8rem',
                  maxWidth: 150
                }}
              ></Chip>
            )}
            {!telegramLinkState && (
              <Chip
                label={"@" + pSettings?.features?.telegram_bot_name}
                sx={{
                  backgroundColor: (theme) => `${isDarkMode ? theme.palette.warning.dark : theme.palette.warning.light}`,
                  '& .MuiChip-label': {
                    overflow: 'visible'
                  },
                  filter: isDarkMode ? 'grayscale(50%)' : '',
                  maxWidth: 150,
                  fontSize: '0.8rem',
                }}
              ></Chip>
            )}
          </ListItemText>
          <ListItemText>
            <Typography
              style={{
                paddingTop: '5px',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                minWidth: '100%',
                filter: isDarkMode ? 'grayscale(50%)' : ''
              }}
              color={'#419FD9'}
            >
              {t('components.atoms.buttons.userButton.menu.telegramLinkButtonEnd')}
            </Typography></ListItemText>
        </div>
      </MenuItem >
    );

  const menuItems = [
    userNameItem,
    telegramLinkItem,
    settingsItem,
    apiKeysItem,

    subscriptionItem,
    creditsItem,
    logoutItem
  ].filter((i) => !!i);

  const itemsWithDivider = menuItems.reduce((acc, curr, i) => {
    if (i === menuItems.length - 1) {
      return [...acc, curr];
    }
    return [...acc, curr, <Divider sx={{ my: 1 }} key={`divider-${i}`} />];
  }, [] as React.ReactNode[]);

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: 240,
          overflow: 'visible',
          mt: 1.5,
          backgroundImage: 'none',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          boxShadow: (theme) =>
            theme.palette.mode === 'light'
              ? '0px 2px 4px 0px #0000000D'
              : '0px 10px 10px 0px #0000000D',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.default',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {itemsWithDivider}
    </Menu>
  );
}
