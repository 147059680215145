import { useFormik } from 'formik';
import mapValues from 'lodash/mapValues';
import {
  ForwardedRef,
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'sonner';

import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@mui/material';

import {
  advancedRerollState,
  toolModalState,
  toolsState,
  useChatInteract
} from '@chainlit/react-client';
import {
  AccentButton,
  RegularButton,
  TFormInputValue
} from '@chainlit/react-components';

import { Translator } from 'components/i18n';
import CustomFormInput from 'components/molecules/inputs/customFormInput';
import useVisibilityQuery from 'components/molecules/inputs/visibility';

import {
  CanvasZoom,
  getNextZoomLevel,
  getPrevZoomLevel,
  getZoomLevel
} from './canvasZoom';
import { DrawOverlayInput } from './drawOverlayInput';
import ToolExampleModal from './toolExampleModal';
import { ZoomOutOverlay } from './zoomOutOverlay';

const imageOverlaySet = new Set(['zoom_out_overlay', 'drawing_image_overlay']);

export default function ToolModal() {
  const tools = useRecoilValue(toolsState);
  const { useTool, uploadSecondaryImage } = useChatInteract();
  const imageOverlayUIRef = useRef<any>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const setAdvancedRerollState = useSetRecoilState(advancedRerollState);
  const [modalState, setToolModalState] = useRecoilState(toolModalState);

  const [isToolExampleModalOpen, setToolExampleModalOpen] = useState(false);

  const modality = modalState?.modality || 'Text';
  const tool = modalState?.tool || 'TextToImage';

  let initialValue = {};
  if (tools && modalState) {
    initialValue = tools[modality][tool].inputs.reduce(
      (form: Record<string, unknown>, input: ChainlitInput) => {
        if (
          typeof input.initial === 'string' ||
          typeof input.initial === 'boolean'
        ) {
          form[input.id] = input.initial;
        } else if (input.initial) {
          form = { ...form, ...input.initial };
        }
        return form;
      },
      {}
    );
  }

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: async () => undefined
  });

  const [zoom, setZoom] = useState(1.0);

  const handleToolExampleModalOpen = () => {
    setToolExampleModalOpen(true);
  };

  const handleToolExampleModalClose = () => {
    setToolExampleModalOpen(false);
  };

  const handleSave = () => {
    setAdvancedRerollState({
      modality: modality,
      toolObject: tools[modality][tool],
      toolName: tool,
      forStepId: modalState?.forStepId || '',
      args: mapValues(formik.values, (x: TFormInputValue) =>
        x !== '' ? x : null
      )
    });
    toast.info(t('multiReroll.advancedRerollSavedSettings'));
  };

  const handleSaveAndRun = () => {
    handleSave();
    handleConfirm();
  };

  const handleClose = () => setToolModalState(null);

  const handleConfirm = async () => {
    try {
      // TODO: this seems better, but make sure it doesn't slow things down
      // for (const elementId of modalState.selectedElementIds ?? []) {
      modalState?.selectedElementIds.forEach(async (elementId) => {
        // Set field value of image overlay UI when the confirm button is clicked
        if (imageOverlayUIRef.current) {
          const { id, name, content, mimeType } =
            await imageOverlayUIRef.current.getValue();
          const { file_id } = (await uploadSecondaryImage(
            name,
            content,
            mimeType
          ))!;
          // Formik.setFieldValue seems to need some interval to reflect to values and
          // there doesn't seem to be a way to properly wait fot the reflection.
          // Therefore, updating values directly as a workaround.
          (formik.values as Record<string, any>)[id] = file_id;
        }

        const values = mapValues(formik.values, (x: TFormInputValue) =>
          x !== '' ? x : null
        );

        // this is where we make the call to the backend
        // TODO: using a hook inside a loop breaks the "rule of hooks"
        const promise = useTool(
          modality,
          tool,
          modalState?.forStepId ?? '',
          elementId || '',
          values
        );
        if (!promise) {
          return;
        }
        toast.promise(promise, {
          error: (result) => result.message || 'Error!'
        });
      });
    } catch (error: unknown) {
      toast.error('Unable to execute tool');
      console.error(error);
      // TODO: log to sentry/google analytics or whatever as well....
    }
    handleClose();
  };

  if (!modalState || !tools) {
    return null;
  }

  let userInputConstraint;
  if (modalState.selectedElementInfoForTools === undefined) {
    userInputConstraint = 'unknown';
  } else {
    const infoForTools = JSON.parse(modalState.selectedElementInfoForTools);
    if (
      infoForTools['constraint'] === undefined ||
      infoForTools['constraint'][modality] === undefined ||
      infoForTools['constraint'][modality][tool] === undefined
    ) {
      userInputConstraint = 'unknown';
    } else {
      userInputConstraint = infoForTools['constraint'][modality][tool];
    }
  }

  const inputIsVisible = useVisibilityQuery(formik);

  const allRequirementsMet = tools[modality][tool].inputs
    .filter((input: any) => input.required && inputIsVisible(input))
    .every(
      (input: any) => !!formik.values[input.id as keyof typeof formik.values]
    );

  const isFullscreen = Boolean(tools[modality][tool].fullscreen);

  // TODO(SAS-642): improve messaging for when no defined
  return (
    <Dialog
      fullScreen={isFullscreen}
      open={modalState?.open || false}
      onClose={handleClose}
      id="chat-settings-dialog"
      PaperProps={{
        sx: {
          backgroundImage: 'none'
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {tools[modality][tool].title}
      </DialogTitle>
      <DialogContent sx={{ padding: isFullscreen ? 0 : undefined }}>
        {tools[modality][tool].example_inputs && (
          <AccentButton
            id="openToolExample"
            variant="outlined"
            sx={{
              paddding: isFullscreen ? 0 : '0 20px 24px 20px',
              position: 'absolute',
              right: { xs: 16, sm: isFullscreen ? 160 : 16 },
              top: 24
            }}
            onClick={handleToolExampleModalOpen}
          >
            <Translator path="components.molecules.toolExampleModal.openToolExample" />
          </AccentButton>
        )}
        {isFullscreen && !isSmallScreen && (
          <CanvasZoom zoom={zoom} setZoom={setZoom} />
        )}
        {userInputConstraint === 'none' ? (
          <Fragment>
            <Box
              sx={{
                paddingLeft: isFullscreen ? '20px' : '0',
                display: 'flex',
                flexDirection: 'column',
                minWidth: '20vw',
                maxHeight: '70vh',
                gap: '15px'
              }}
            >
              {modalState.selectedElementIds.length > 1 && (
                <Alert variant="outlined" severity="info">
                  {t('multiReroll.toolUseImageCountMessagePrefix') +
                    ' ' +
                    modalState.selectedElementIds.length.toString() +
                    ' ' +
                    t('multiReroll.toolUseImageCountMessageSuffix')}
                </Alert>
              )}
              {tools[modality][tool].inputs
                .filter((input: any) => !imageOverlaySet.has(input.type))
                .map((input: any) => (
                  <CustomFormInput
                    key={input.id}
                    element={{
                      ...input,
                      value:
                        formik.values[input.id as keyof typeof formik.values],
                      onChange: formik.handleChange,
                      setField: formik.setFieldValue
                    }}
                    visible={inputIsVisible(input)}
                  />
                ))}
            </Box>
            {modalState.selectedElementUrl && (
              <ImageMaybeWithOverlayUI
                ref={imageOverlayUIRef}
                url={modalState.selectedElementUrl}
                zoom={zoom}
                setZoom={setZoom}
                inputs={tools[modality][tool].inputs}
                formik={formik}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            {(() => {
              switch (userInputConstraint) {
                case 'user':
                  return (
                    <Translator path="components.organisms.chat.settings.not_available_user_upload" />
                  );
                case 'sensitive':
                  return (
                    <Translator path="components.organisms.chat.settings.not_available_sensitive_user_upload" />
                  );
                default: // include unknown
                  return (
                    <Translator path="components.organisms.chat.settings.not_available_fallback_user_upload" />
                  );
              }
            })()}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {isFullscreen && isSmallScreen && (
          <CanvasZoom zoom={zoom} setZoom={setZoom} />
        )}
        <RegularButton onClick={handleClose}>
          <Translator path="components.organisms.chat.settings.cancel" />
        </RegularButton>
        {userInputConstraint === 'none' ? (
          <AccentButton
            id="confirm"
            variant="outlined"
            disabled={!allRequirementsMet}
            onClick={
              !modalState.isConfiguringAdvancedReroll
                ? handleConfirm
                : handleSaveAndRun
            }
            autoFocus
          >
            {!modalState.isConfiguringAdvancedReroll && (
              <Translator path="components.organisms.chat.settings.confirm" />
            )}
            {modalState.isConfiguringAdvancedReroll && (
              <Translator path="components.organisms.chat.settings.saveAndRun" />
            )}
          </AccentButton>
        ) : null}
      </DialogActions>

      {tools[modality][tool].example_inputs && (
        <ToolExampleModal
          open={isToolExampleModalOpen}
          handleClose={handleToolExampleModalClose}
          inputs={tools[modality][tool].example_inputs}
        />
      )}
    </Dialog>
  );
}

type ChainlitInput = {
  id: string;
  type: string;
  label: string;
  initial: string | Record<string, unknown>;
  description: string;
  required?: boolean;
};

type ImageMaybeWithOverlayUIProps = {
  url: string;
  formik: ReturnType<typeof useFormik>;
  inputs: ChainlitInput[];
  zoom: number;
  setZoom: (zoom: number) => void;
};

const ImageMaybeWithOverlayUI = forwardRef(function ImageMaybeWithOverlayUI(
  { url, inputs, formik, zoom, setZoom }: ImageMaybeWithOverlayUIProps,
  ref: ForwardedRef<any>
): JSX.Element {
  // Expects up to one image overlay UI
  const overlayInput = inputs.find((input) => imageOverlaySet.has(input.type));

  useEffect(() => {
    const keyboardZoomHandler = (ev: KeyboardEvent) => {
      if (!ev.metaKey && !ev.ctrlKey) return;
      if (ev.code === 'Minus' || ev.code === 'Equal') {
        ev.preventDefault();
        const nextZoom =
          ev.code === 'Minus' ? getPrevZoomLevel(zoom) : getNextZoomLevel(zoom);
        setZoom(nextZoom);
      }
    };
    document.addEventListener('keydown', keyboardZoomHandler);
    return () => {
      document.removeEventListener('keydown', keyboardZoomHandler);
    };
  }, [zoom]);

  switch (overlayInput?.type) {
    case 'zoom_out_overlay': {
      return (
        <ZoomOutOverlay
          onCanvasReady={(canvasSize) => {
            setZoom(getZoomLevel(...canvasSize));
          }}
          zoom={zoom}
          id={overlayInput.id}
          formik={formik}
          imgUrl={url}
        />
      );
    }
    case 'drawing_image_overlay':
      return (
        <DrawOverlayInput
          id={overlayInput.id}
          onCanvasReady={(canvasSize) => {
            setZoom(getZoomLevel(...canvasSize));
          }}
          handleZoomIn={() => {
            setZoom(getNextZoomLevel(zoom));
          }}
          handleZoomOut={() => {
            setZoom(getPrevZoomLevel(zoom));
          }}
          zoom={zoom}
          ref={ref}
          label={overlayInput.label}
          description={overlayInput.description}
          imgUrl={url}
        />
      );
    default:
      return (
        <img
          id="elementImage"
          src={url}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      );
  }
});
