import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Circle } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { projectSettingsState } from 'state/project';

import UserAvatar from './avatar';
import UserMenu from './menu';

export default function UserButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pSettings = useRecoilValue(projectSettingsState);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showRedCircle, setShowRedCircle] = useState(
    localStorage.getItem('hasOpenedUserMenuSinceTelegram') !== 'true'
  );

  return (
    <div>
      <IconButton
        edge="end"
        onClick={(e) => {
          handleClick(e);
          if (pSettings?.features.telegram_bot_name) {
            setShowRedCircle(false);
            localStorage.setItem('hasOpenedUserMenuSinceTelegram', 'true');
          }
        }}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <UserAvatar />
        {pSettings?.features.telegram_bot_name && showRedCircle && (
          <Circle
            sx={{
              alignItems: 'flex-start',
              position: 'absolute',
              transform: 'scale(0.6)',
              top: 0,
              left: '5%',
              color: '#ff0000'
            }}
          />
        )}
      </IconButton>
      <UserMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  );
}
