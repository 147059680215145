import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

import {
  ImageAspectRatio,
  aspectRatioState,
  sessionState,
  useChatInteract
} from '@chainlit/react-client';

import RedDot from 'components/molecules/redDot';

type Ratio = `${number}/${number}`;

const imageAspectRatios: Record<ImageAspectRatio, Ratio> = {
  DEFAULT: '1/1',
  WIDESCREEN: '16/9',
  LANDSCAPE: '3/2',
  SQUARE: '1/1',
  PORTRAIT: '4/5',
  VERTICAL: '9/16'
};

type AspectRatioButtonProps = {
  disabled?: boolean;
};

const AspectRatioButton = ({ disabled }: AspectRatioButtonProps) => {
  const { sendAspectRatio } = useChatInteract();
  const [aspectRatio, setAspectRatio] = useRecoilState(aspectRatioState);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();

  // anytime we have a new connection we need to sync the aspect ratio
  const session = useRecoilValue(sessionState);
  const [prevSessionId, setPrevSessionId] = useState('');
  useEffect(() => {
    if (session?.socket.id && session?.socket.id !== prevSessionId) {
      sendAspectRatio(aspectRatio.aspectRatio);
      setPrevSessionId(session?.socket.id);
    }
  }, [prevSessionId, session, session?.socket.id]);

  const closeModal = () => {
    setAspectRatio((state) => ({ ...state, open: false }));
  };

  const selectAspectRatio = async (aspectRatio: ImageAspectRatio) => {
    setAspectRatio({ aspectRatio, open: false, isUsed: true });
    sendAspectRatio(aspectRatio);
  };

  return (
    <>
      <Menu
        anchorEl={anchorRef.current}
        open={aspectRatio.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={closeModal}
      >
        <li>
          <Typography
            color={theme.palette.text.secondary}
            component={'h3'}
            textAlign={'center'}
            fontWeight={'bold'}
            padding=".5em"
          >
            {t('aspectRatioSelector.title')}
          </Typography>
        </li>
        <li>
          <hr
            style={{ height: 1, border: 0 }}
            color={theme.palette.grey[300]}
          />
        </li>
        {Object.entries(imageAspectRatios).map(([imageAspectRatio, ratio]) => {
          return (
            <AspectRatioMenuItem
              key={imageAspectRatio}
              ratio={ratio}
              title={t(`aspectRatioSelector.sizes.${imageAspectRatio}`)}
              imageAspectRatio={imageAspectRatio as ImageAspectRatio}
              color={
                imageAspectRatio === aspectRatio.aspectRatio
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              onClick={selectAspectRatio}
            />
          );
        })}
      </Menu>
      <Tooltip title={t('aspectRatioSelector.tooltip')} placement="bottom">
        <div>
          <IconButton
            id="aspect-ratio-open-modal"
            ref={anchorRef}
            disabled={disabled}
            color="inherit"
            onClick={() => {
              setAspectRatio((state) => ({
                ...state,
                open: true,
                isUsed: true
              }));
            }}
          >
            {!aspectRatio.isUsed && <RedDot top="-2px" right="-2px" />}
            <Stack alignItems="center">
              <AspectRatioBox
                size={0.38}
                color={theme.palette.text.secondary}
                ratio={imageAspectRatios[aspectRatio.aspectRatio]}
              />
            </Stack>
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default AspectRatioButton;

const AspectRatioBox = ({
  color = 'grey',
  ratio,
  size = 0.75
}: {
  color: string;
  ratio: Ratio;
  size?: number;
}) => {
  const outerbox = size * 2.85;

  return (
    <div
      style={{
        border: `2px solid ${color}`,
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: `${outerbox}em`,
        width: `${outerbox}em`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {ratio && (
        <div
          style={{
            height: `${size}em`,
            margin: '2px',
            border: `2px dashed ${color}`,
            aspectRatio: ratio
          }}
        />
      )}
    </div>
  );
};

const AspectRatioMenuItem = ({
  color,
  onClick,
  imageAspectRatio,
  title,
  ratio
}: {
  color: string;
  imageAspectRatio: ImageAspectRatio;
  title: string;
  ratio: Ratio;
  onClick: (selectedAspectRatio: ImageAspectRatio) => void;
}) => {
  return (
    <MenuItem onClick={() => onClick(imageAspectRatio)}>
      <Stack direction="row" gap=".5em" alignItems="center">
        <AspectRatioBox ratio={ratio} color={color} />
        <Typography color={color}>{title}</Typography>
      </Stack>
    </MenuItem>
  );
};
