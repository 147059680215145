import { TCustomFormInput } from './customFormInput';

const useVisibilityQuery = (formik: { values: { [key: string]: any } }) => {
  const inputIsVisible = (element: TCustomFormInput): boolean => {
    if (!element.visibleWhen) {
      return true;
    } else {
      return (
        formik.values[element.visibleWhen.id] === element.visibleWhen.value
      );
    }
  };

  return inputIsVisible;
};

export default useVisibilityQuery;
