import { atom } from 'recoil';

import { FileSpec } from '@chainlit/react-client';

export interface IAttachment {
  id: string;
  threadId?: string;
  serverId?: string;
  name: string;
  size: number;
  type: string;
  uploadProgress?: number;
  uploaded?: boolean;
  elementInfoForTools?: string;
  cancel?: () => void;
  remove?: () => void;
}

export const attachmentsState = atom<IAttachment[]>({
  key: 'Attachments',
  default: []
});

export const fileSpecState = atom<FileSpec>({
  key: 'fileSpec',
  default: { max_size_mb: 32 }
});
