import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Page from 'pages/Page';

import {
  firstUserInteraction,
  sessionState,
  useChatSession
} from '@chainlit/react-client';

import Chat from 'components/organisms/chat/index';

export default function Home() {
  const params = useParams();
  const navigate = useNavigate();
  const { changeThread } = useChatSession();
  const session = useRecoilValue(sessionState);
  const firstInteraction = useRecoilValue(firstUserInteraction);

  const currentThreadId = params.id;
  const [lastThreadId, setLastThreadId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      currentThreadId &&
      currentThreadId === lastThreadId &&
      currentThreadId !== session?.threadId
    ) {
      setLastThreadId(session?.threadId);
      navigate(session?.threadId ? `/thread/${session.threadId}` : '/');
    } else if (currentThreadId && currentThreadId !== session?.threadId) {
      setLastThreadId(currentThreadId);
      changeThread(currentThreadId);
    } else if (
      firstInteraction &&
      session?.threadId !== currentThreadId &&
      !currentThreadId
    ) {
      navigate(session?.threadId ? `/thread/${session.threadId}` : '/');
    }
  }, [params, session, firstInteraction]);

  return (
    <Page>
      <Chat />
    </Page>
  );
}
