import { useAuth } from 'api/auth';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { router } from 'router';
import { Toaster } from 'sonner';

import { Box, GlobalStyles } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';

import { productsState, useChatSession } from '@chainlit/react-client';
import { makeTheme } from '@chainlit/react-components/theme';

import Hotkeys from 'components/Hotkeys';
import CookiePopup from 'components/molecules/cookiePopup';
import GenerationExampleModal from 'components/molecules/generationExampleModal';
import GenerationShareModal from 'components/molecules/generationShareModal';
import ImageModal from 'components/molecules/imageModal';
import {
  BackgroundSelectorModal,
  StyleSelectorModal
} from 'components/molecules/mediaSelector';
import SettingsModal from 'components/molecules/settingsModal';
import ToolsModal from 'components/molecules/toolsModal';
import ChatSettingsModal from 'components/organisms/chat/settings';
import PromptPlayground from 'components/organisms/playground';

import { apiClientState } from 'state/apiClient';
import { projectSettingsState } from 'state/project';
import { settingsState } from 'state/settings';
import { userEnvState } from 'state/user';

import './App.css';

type Primary = {
  dark?: string;
  light?: string;
  main?: string;
};

type ThemOverride = {
  primary?: Primary;
  background?: string;
  paper?: string;
};

declare global {
  interface Window {
    theme?: {
      light?: ThemOverride;
      dark?: ThemOverride;
    };
  }
}

export function overrideTheme(theme: Theme) {
  const variant = theme.palette.mode;
  const variantOverride = window?.theme?.[variant] as ThemOverride;
  if (variantOverride?.background) {
    theme.palette.background.default = variantOverride.background;
  }
  if (variantOverride?.paper) {
    theme.palette.background.paper = variantOverride.paper;
  }
  if (variantOverride?.primary?.main) {
    theme.palette.primary.main = variantOverride.primary.main;
  }
  if (variantOverride?.primary?.dark) {
    theme.palette.primary.dark = variantOverride.primary.dark;
  }
  if (variantOverride?.primary?.light) {
    theme.palette.primary.light = variantOverride.primary.light;
  }

  return theme;
}

function App() {
  const { theme: themeVariant } = useRecoilValue(settingsState);
  const pSettings = useRecoilValue(projectSettingsState);
  // @ts-expect-error custom property
  const fontFamily = window.theme?.font_family;
  const theme = overrideTheme(makeTheme(themeVariant, fontFamily));
  const { isAuthenticated, accessToken } = useAuth();
  const userEnv = useRecoilValue(userEnvState);
  const { session, connect, chatProfile, setChatProfile } = useChatSession();
  const apiClient = useRecoilValue(apiClientState);
  const setProductsState = useSetRecoilState(productsState);

  const pSettingsLoaded = !!pSettings;

  const fetchProducts = async () => {
    const res = await apiClient.safe_post(`/products`, {}, accessToken);

    return res?.json();
  };

  useEffect(() => {
    fetchProducts()
      .then((data) => {
        setProductsState(
          data === undefined || data.products === null
            ? undefined
            : data.products
        );
      })
      .catch();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    } else if (!pSettingsLoaded) {
      return;
    } else if (!session?.socket.active) {
      connect({
        client: apiClient,
        userEnv,
        accessToken
      });
    }
  }, [userEnv, accessToken, isAuthenticated, pSettingsLoaded]);

  if (pSettingsLoaded && pSettings.chatProfiles.length && !chatProfile) {
    // Autoselect the chat profile if there is only one
    setChatProfile(pSettings.chatProfiles[0].name);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: theme.palette.background.default }
        }}
      />
      <Toaster
        className="toast"
        position="top-right"
        toastOptions={{
          style: {
            fontFamily: theme.typography.fontFamily,
            background: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.text.primary
          }
        }}
      />
      <Box
        display="flex"
        height="100vh"
        width="100vw"
        sx={{ overflowX: 'hidden' }}
      >
        <PromptPlayground />
        <ChatSettingsModal />
        <Hotkeys />
        <SettingsModal />
        <ToolsModal />
        <ImageModal />
        <StyleSelectorModal />
        <BackgroundSelectorModal />
        <GenerationShareModal />
        <GenerationExampleModal />
        <RouterProvider router={router} />
        <CookiePopup />
      </Box>
    </ThemeProvider>
  );
}

export default App;
