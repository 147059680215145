import { atom, useRecoilState, useRecoilValue } from 'recoil';

import AttachFile from '@mui/icons-material/AttachFile';
import Circle from '@mui/icons-material/Circle';
import { Box, IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';

import { AccentButton, useUpload } from '@chainlit/react-components';
import { grey } from '@chainlit/react-components/theme';

import { Translator } from 'components/i18n';

import { fileSpecState } from 'state/chat';
import { projectSettingsState } from 'state/project';

const attachmentButtonClickedKey = 'attachmentButtonClicked';

const loadAttachmentButtonClicked = () => {
  const clickedJson = localStorage.getItem(attachmentButtonClickedKey);
  return clickedJson === null ? false : JSON.parse(clickedJson);
};

const storeAttachmentButtonClicked = (clicked: boolean) => {
  localStorage.setItem(attachmentButtonClickedKey, JSON.stringify(clicked));
};

const showRedCircleState = atom<boolean>({
  key: 'showRedCircleStateOnAttachmentButton',
  default: !loadAttachmentButtonClicked(),
  effects: [
    ({ onSet }) => {
      onSet((newValue: boolean) => {
        storeAttachmentButtonClicked(!newValue);
      });
    }
  ]
});

type Props = {
  disabled?: boolean;
  onFileUpload: (files: File[]) => void;
  onFileUploadError: (error: string) => void;
  withLabel?: boolean;
};

const UploadButton = ({
  disabled,
  onFileUpload,
  onFileUploadError,
  withLabel
}: Props) => {
  const pSettings = useRecoilValue(projectSettingsState);
  const fileSpec = useRecoilValue(fileSpecState);

  const upload = useUpload({
    spec: fileSpec,
    onResolved: (payloads: File[]) => onFileUpload(payloads),
    onError: onFileUploadError,
    options: { noDrag: true, multiple: false }
  });

  const size = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
    ? 'small'
    : 'medium';

  const [showRedCircle, setShowRedCircle] = useRecoilState(showRedCircleState);

  if (!upload || !pSettings?.features?.multi_modal) return null;
  const { getRootProps, getInputProps } = upload;

  const rootProps = getRootProps({ classname: 'dropzone' });

  const onClick = (e: React.MouseEvent) => {
    setShowRedCircle(false);
    (rootProps as any)?.onClick(e);
  };

  const Button = withLabel ? AccentButton : IconButton;

  return (
    <Tooltip
      title={
        <Translator path="components.organisms.chat.inputBox.UploadButton.attachFiles" />
      }
    >
      <span>
        <input id="upload-button-input" {...getInputProps()} />
        <Button
          id={disabled ? 'upload-button-loading' : 'upload-button'}
          disabled={disabled}
          size={size}
          variant="outlined"
          {...rootProps}
          onClick={onClick}
        >
          <AttachFile fontSize={size} />
          {/* Don't forget to remove this red circle when we release further new features. */}
          {/* TODO: Create reusable red circle component */}
          {showRedCircle && (
            <Circle
              sx={{
                position: 'absolute',
                transform: 'scale(0.5)',
                top: 0,
                right: 0,
                color: (theme) => {
                  return disabled
                    ? theme.palette.mode === 'dark'
                      ? grey[700]
                      : grey[300]
                    : '#ff0000';
                }
              }}
            />
          )}

          {withLabel ? (
            <Box fontSize={size} px={1}>
              <Translator path="components.organisms.chat.inputBox.UploadButton.upload" />
            </Box>
          ) : null}
        </Button>
      </span>
    </Tooltip>
  );
};

export default UploadButton;
