import { RecoilState } from 'recoil';

import {
  ImageSelectorModalProps,
  UploadContext,
  backgroundSelectorModalState,
  styleSelectorModalState,
  useChatInteract
} from '@chainlit/react-client';

import ImageSelectorModal from './modal';

export const getMediaSelectorTranslationKey = (
  uploadContext: UploadContext
): string => {
  return `${uploadContext}SelectorModal`;
};

export const getMediaSelectorState = (
  uploadContext: UploadContext
): RecoilState<ImageSelectorModalProps> => {
  switch (uploadContext) {
    case 'style':
      return styleSelectorModalState;
    case 'background':
    default:
      return backgroundSelectorModalState;
  }
};

export const useMediaSelectorImageSelector = (uploadContext: UploadContext) => {
  if (uploadContext === 'style') {
    const { setStyleImage } = useChatInteract();
    return setStyleImage;
  } else {
    return () => {};
  }
};

interface StatefulImageSelectorModalProps {
  uploadContext: UploadContext;
}

const StatefulImageSelectorModal = ({
  uploadContext
}: StatefulImageSelectorModalProps) => {
  const state = getMediaSelectorState(uploadContext);
  const translationKey = getMediaSelectorTranslationKey(uploadContext);
  const onSelectImage = useMediaSelectorImageSelector(uploadContext);

  return (
    <ImageSelectorModal
      state={state}
      translationKey={translationKey}
      onSelectImage={onSelectImage ?? (() => {})}
      uploadContext={uploadContext}
    />
  );
};

export const StyleSelectorModal = () => {
  return <StatefulImageSelectorModal uploadContext="style" />;
};

export const BackgroundSelectorModal = () => (
  <StatefulImageSelectorModal uploadContext="background" />
);
