import { atom } from 'recoil';

const toolMenuStorageKey = 'toolMenuHistory';
const userImageMenuStorageKey = 'userImageToolHistory';
const toolboxClickedStorageKey = 'toolboxButtonClicked';

// legacy saved data will also still need to work (for a while...)
const legacyKeys = {
  Inpaint: 'inpaintClicked',
  Erase: 'eraseClicked',
  ImageTo3D: 'imageTo3DClicked'
};

const getLegacyToolboxHistory = () => {
  return Object.entries(legacyKeys)
    .filter(([, storageKey]) => localStorage.getItem(storageKey) === 'true')
    .map(([tool]) => tool);
};

const loadValueForKey = <T>(key: string, getDefaultValue: () => T): T => {
  const value = localStorage.getItem(key);
  return value == null ? getDefaultValue() : JSON.parse(value);
};

const saveValueForKey = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const toolboxClickedState = atom<boolean>({
  key: 'showRedCircleStateOnToolboxButton',
  default: loadValueForKey(toolboxClickedStorageKey, () => false),
  effects: [
    ({ onSet }) => {
      onSet((newValue: boolean) => {
        saveValueForKey(toolboxClickedStorageKey, newValue);
      });
    }
  ]
});

export const toolHistory = atom<string[]>({
  key: 'toolHistory',
  default: loadValueForKey(toolMenuStorageKey, getLegacyToolboxHistory),
  effects: [
    ({ onSet }) => {
      onSet((newValue: string[]) => {
        saveValueForKey(toolMenuStorageKey, newValue);
      });
    }
  ]
});

export const userImageToolHistory = atom<string[]>({
  key: 'userImageToolHistory',
  default: loadValueForKey(userImageMenuStorageKey, () => [] as string[]),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        saveValueForKey(userImageMenuStorageKey, newValue);
      });
    }
  ]
});
